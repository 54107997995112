import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';

// functions
import { ThaiDateTime } from '../../functions/FuncDateTimes';
import { shortHospName } from '../../functions/FuncPerjer';

// constants
import { COMPLETE, PENDING, REJECTED } from '../../constants/Refers/referralStatus';

// images
import LabIcon from '../../images/Lab-Icon.346a49db.png';
import XrayIcon from '../../images/Xray-Icon.2bdb3b5d.png';
import DentIcon from '../../images/dentist-tooth-care-512.png';
import NoteIcon from '../../images/note27-512.png';
import ReferIcon from '../../images/Medical-02-512.png';
import AdmitIcon from '../../images/bed21-512.b319bd27.png';
import AppointmentIcon from '../../images/Events_Calendar-512.png';

// mui
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Avatar from '@material-ui/core/Avatar';

const session = orm.session();

class VisitsData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pthn: '',
      pthnShow: '',
      visitsData: [],
      visitSelect: '',
      cid: '',
      hospCodeData: [],
      hospCode: 'all',
    };
    this.getVisitsData = this.getVisitsData.bind(this);
    this.setAppData = this.setAppData.bind(this);
    this.setVisitSelect = this.setVisitSelect.bind(this);
    this.hospCodeChange = this.hospCodeChange.bind(this);
  }

  hospCodeChange(event) {
    this.setState({ hospCode: event.target.value });

    let visitsData = [];

    if (event.target.value !== 'all') {
      visitsData = session.Visits.all()
        .filter(Visits => Visits.cid === this.state.cid)
        .filter(Visits => Visits.class === 'AMB')
        .filter(Visits => Visits.hospCode === event.target.value)
        .orderBy(Visits => Visits.visitDateTime, 'desc')
        .toRefArray();
    } else {
      visitsData = session.Visits.all()
        .filter(Visits => Visits.cid === this.state.cid)
        .filter(Visits => Visits.class === 'AMB')
        .orderBy(Visits => Visits.visitDateTime, 'desc')
        .toRefArray();
    }

    const appPropertyData = {
      visitSelect: visitsData[0].bid,
      FetchingStatus: false,
    };

    this.setAppData(appPropertyData);

    const { params } = this.props.match;
    let cid = '';
    if (params.cid) {
      cid = params.cid;
      if (visitsData[0].bid !== this.props.appProperty.visitSelect) {
        this.props.history.push('/patient-emr/' + cid + '/' + visitsData[0].bid);
      }
    }

    // visitsData
    this.setState({ visitSelect: visitsData[0].bid });
    this.setState({ visitsData: visitsData });
  }

  setVisitSelect(bid) {
    const { params } = this.props.match;
    let cid = '';
    if (params.cid) {
      cid = params.cid;
      if (bid !== this.props.appProperty.visitSelect) {
        this.props.history.push('/patient-emr/' + cid + '/' + bid);
      }
    }

    const appPropertyData = {
      visitSelect: bid,
      visitMenuClose: true,
      FetchingStatus: false,
    };

    this.setAppData(appPropertyData);
  }

  setAppData(appPropertyData) {
    this.props.appPropertySet(appPropertyData);
  }

  getVisitsData(cid, bid) {
    const visits = this.props.appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.cid === cid;
      },
    });

    session.VisitsHosp.delete({
      where(record) {
        return record.cid === cid;
      },
    });

    for (var val of visits) {
      session.Visits.upsert(val);

      if (val.hospCode && val.class === 'AMB') {
        session.VisitsHosp.upsert({
          id: val.hospCode,
          cid: cid,
          hospName: val.hospName ? shortHospName(val.hospName) : val.hospCode,
        });
      }
    }

    let visitsData = session.Visits.all()
      .filter(Visits => Visits.cid === cid)
      .filter(Visits => Visits.class === 'AMB')
      .orderBy(Visits => Visits.visitDateTime, 'desc')
      .toRefArray();

    let hospCodeData2 = session.VisitsHosp.all()
      .filter(VisitsHosp => VisitsHosp.cid === cid)
      .toRefArray();

    this.setState({ hospCodeData: hospCodeData2 });

    let appPropertyData = null;

    let visitsDataBid = '';

    if (bid !== '') {
      appPropertyData = {
        visitSelect: bid,
        FetchingStatus: false,
      };
      visitsDataBid = bid;
    } else {
      appPropertyData = {
        visitSelect: visitsData[0].bid,
        FetchingStatus: false,
      };
      visitsDataBid = visitsData[0].bid;
    }

    this.setAppData(appPropertyData);

    // visitsData
    if (visitsData.length > 0) {
      const { params } = this.props.match;
      let cid = '';
      if (params.cid) {
        cid = params.cid;

        if (params.bid) {
          if (params.bid !== this.props.appProperty.visitSelect) {
            this.props.history.push('/patient-emr/' + cid + '/' + params.bid);
          }
        } else {
          if (visitsDataBid !== this.props.appProperty.visitSelect) {
            this.props.history.push('/patient-emr/' + cid + '/' + visitsDataBid);
          }
        }
      }

      this.setState({ visitSelect: visitsDataBid });
    }

    this.setState({ visitsData: visitsData });
  }

  componentDidUpdate() {
    const { params } = this.props.match;

    let bid = '';

    if (params.cid) {
      if (params.bid) {
        if (this.state.visitSelect !== params.bid) {
          bid = params.bid;
        }
      }

      if (
        !params.bid &&
        this.props.cid &&
        this.props.ptId &&
        (this.state.cid <= '' || this.state.cid !== this.props.cid)
      ) {
        this.setState({ pthnShow: this.props.hn });
        this.setState({ cid: this.props.cid });

        this.getVisitsData(this.props.cid, '');
      } else if (
        params.bid &&
        this.props.cid &&
        this.props.ptId &&
        (this.state.cid <= '' || this.state.cid !== this.props.cid)
      ) {
        this.setState({ pthnShow: this.props.hn });
        this.setState({ cid: this.props.cid });
        this.getVisitsData(this.props.cid, bid);
      } else if (params.bid && this.props.cid && this.props.ptId && params.bid !== this.state.visitSelect) {
        this.setState({ visitSelect: params.bid });
        const appPropertyData = {
          visitSelect: params.bid,
          visitMenuClose: true,
          FetchingStatus: false,
        };

        this.setAppData(appPropertyData);
      }
    }
  }

  render() {
    return (
      <div>
        <FormControl style={{ width: '100%' }} className='margin-bottom-4px' error variant='filled'>
          <InputLabel id='demo-simple-select-label'>โรงพยาบาล</InputLabel>
          <Select
            labelId='hospcode-select'
            id='hospcode-select'
            value={this.state.hospCode}
            onChange={this.hospCodeChange}
          >
            <MenuItem value='all'>ทั้งหมด</MenuItem>
            {this.state.hospCodeData.map((items, index) => (
              <MenuItem key={index} value={items.id}>
                {items.hospName ? items.hospName : items.hospCode}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Card
          style={{
            maxHeight:
              this.props.height - this.props.widthSub < this.props.maxHeight
                ? this.props.maxHeight
                : this.props.height - this.props.widthSub + 'px',
            overflowY: 'auto',
          }}
          className='overflow-scrolling-touch'
        >
          <List
            component='nav'
            aria-label='mailbox folders'
            className={this.state.visitsData.length > 0 ? 'visits-data-primary' : 'visits-data-primary object-hidden'}
            style={{
              overflowX: 'hidden',
              overflowWrap: 'break-word',
            }}
          >
            <Divider />
            {this.state.visitsData.map((items, index) => (
              <div key={index}>
                <ListItem
                  button
                  className={this.props.appProperty.visitSelect === items.bid ? 'visits-data-active' : ''}
                >
                  <div className='object-hidden'>
                    <Checkbox color='secondary' className='checkbox-visit' />
                  </div>
                  <div
                    className='display-block'
                    style={{ width: '100%' }}
                    b-id={items.bid}
                    onClick={() => {
                      this.setVisitSelect(items.bid);
                    }}
                  >
                    <div className='margin-bottom-4px'>
                      {items.hospName ? shortHospName(items.hospName) : items.hospCode}
                    </div>
                    <div className='margin-bottom-4px'>{ThaiDateTime(items.visitDateTime)}</div>
                    <div className='margin-bottom-4px'>{items.departmentName}</div>
                    <div style={{ float: 'left' }}>
                      {items.labResults.length > 0 && (
                        <Avatar alt='Lab' title='Lab' src={LabIcon} className='icon-visit' />
                      )}
                      {items.xrays.length > 0 && (
                        <Avatar alt='X-ray' title='X-ray' src={XrayIcon} className='icon-visit' />
                      )}
                      {items.dentals.length > 0 && (
                        <Avatar
                          alt='Dental'
                          title='Dental'
                          src={DentIcon}
                          className='icon-visit'
                          style={{ backgroundColor: 'black' }}
                        />
                      )}
                      {items.doctorNotes.length > 0 && (
                        <Avatar alt='Doctor Note' title='Doctor Note' src={NoteIcon} className='icon-visit' />
                      )}

                      {items.an && (
                        <Avatar
                          alt='Admit'
                          title='Admit'
                          src={AdmitIcon}
                          className='icon-visit'
                          style={{ backgroundColor: '#e5f132' }}
                        />
                      )}

                      {items.appointments.length > 0 && (
                        <Avatar
                          alt='Appointment (การนัดหมาย)'
                          title='Appointment (การนัดหมาย)'
                          src={AppointmentIcon}
                          className='icon-visit'
                          style={{ backgroundColor: '#e5f132' }}
                        />
                      )}

                      {items.referrals.length > 0 && items.referrals[0].status?.toUpperCase() === PENDING && (
                        <Avatar
                          alt='Refer'
                          title='Refer'
                          src={ReferIcon}
                          className='icon-visit'
                          style={{
                            backgroundColor: '#e5f132',
                            padding: '3px',
                          }}
                        />
                      )}

                      {items.referrals.length > 0 && items.referrals[0].status?.toUpperCase() === COMPLETE && (
                        <Avatar
                          alt='Refer'
                          title='Refer'
                          src={ReferIcon}
                          className='icon-visit'
                          style={{
                            backgroundColor: '#4CAF50',
                            padding: '3px',
                          }}
                        />
                      )}

                      {items.referrals.length > 0 && items.referrals[0].status?.toUpperCase() === REJECTED && (
                        <Avatar
                          alt='Refer'
                          title='Refer'
                          src={ReferIcon}
                          className='icon-visit'
                          style={{
                            backgroundColor: '#F44336',
                            padding: '3px',
                          }}
                        />
                      )}
                    </div>
                  </div>
                </ListItem>

                <Divider />
              </div>
            ))}
          </List>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VisitsData));
