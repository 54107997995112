import axios from 'axios';
import config from 'react-global-configuration';

export default dataRequest => {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      const { idToken, monthIndex, categoryCode } = dataRequest;

      const result = await axios.post(`${config.get('ROOT_API')}/v1/billing/recalculateAndGetSummary`, dataRequest, {
        headers: { Authorization: `Bearer ${idToken}` },
      });

      const billingData = {
        calculateSummary: result.data.result.data[monthIndex].categories.find(
          category => category.categoryCode === categoryCode
        ),
        FetchingStatus: false,
      };

      return resolve(billingData);
    }, 200);
  });
};
