import axios from 'axios';
import config from 'react-global-configuration';

const UpdateVisitStatus = ({ idToken, cid, vn, statusToUpdate, provider, payer, year, categoryId }) => {
  let dispatch = {};
  const url = `${config.get('ROOT_API')}/v1/billingitems/updatevisitstatus`;

  const body = {
    cid,
    vn,
    statusToUpdate,
    provider,
    payer,
    year,
    categoryId,
  };

  return axios
    .post(url, body, { headers: { Authorization: `Bearer ${idToken}` } })
    .then(res => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(err => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};

export default UpdateVisitStatus;
