import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

// actions
import { attachFiles } from '../../../../actions/Refers/ReferOut';

// mui
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { DropzoneArea } from 'material-ui-dropzone';

export default function ReferAttachDialog(props) {
  const { handleSubmit, control } = useForm();
  const dispatch = useDispatch();

  const onSubmit = async ({ attachments }) => {
    await dispatch(
      attachFiles({ idToken: props.idToken, attachments, bid: props.bid, existsAttachments: props.attachments })
    );

    props.onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={props.open}
      onClose={() => props.onClose()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='refer-dialog-attach'
    >
      <DialogTitle id='alert-dialog-title'>
        <strong>แนบไฟล์</strong>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            as={<DropzoneArea maxFileSize={8388608} acceptedFiles={['image/jpeg', 'image/png', '.pdf', '.doc', '.docx']} />}
            control={control}
            name='attachments'
          />
        </DialogContent>
        <DialogActions>
          <Button type='submit' color='primary' variant='contained'>
            แนบไฟล์
          </Button>
          <Button onClick={() => props.onClose()} color='secondary' variant='contained' autoFocus>
            ยกเลิก
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
