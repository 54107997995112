import React, { Component } from 'react';
import { connect } from 'react-redux';
import ApexCharts from 'react-apexcharts';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchRefers } from '../../actions/ReferDatas';

// functions
import { ThaiDate } from '../../functions/FuncDateTimes';
import { setReportHeight } from '../../functions/Report';

// mui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const session = orm.session();

class ReferOutDiag4BranchChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ReferDatas: [],
      ReferDiag: [],
      OptionsData: {},
      SeriesData: [],
    };
    this.ReferProcess = this.ReferProcess.bind(this);
  }

  ReferProcess() {
    const referDb = this.state.ReferDatas;

    session.ReferOutDatas.delete({
      where(record) {
        return record._id !== '';
      },
    });

    session.TempData.delete({
      where(record) {
        return record.id !== '';
      },
    });

    session.Temp2Data.delete({
      where(record) {
        return record.id !== '';
      },
    });

    session.Temp2Data.upsert({
      id: 'b1',
      branchDx: 'b1',
      branchDxName: '1.อุบัติเหตุ',
      orderBy: 1,
    });
    session.Temp2Data.upsert({
      id: 'b2',
      branchDx: 'b2',
      branchDxName: '2. หัวใจ',
      orderBy: 2,
    });
    session.Temp2Data.upsert({
      id: 'b3',
      branchDx: 'b3',
      branchDxName: '3. มะเร็ง',
      orderBy: 3,
    });
    session.Temp2Data.upsert({
      id: 'b4',
      branchDx: 'b4',
      branchDxName: '4. ทารกแรกเกิด',
      orderBy: 4,
    });

    for (var val of referDb) {
      session.ReferOutDatas.upsert(val);

      if (
        (val?.data?.icd?.toUpperCase() >= 'S00' && val?.data?.icd?.toUpperCase() <= 'T98') ||
        (val?.data?.icd?.toUpperCase() >= 'V01' && val?.data?.icd?.toUpperCase() <= 'Y9899')
      ) {
        session.TempData.upsert({
          id: val._id,
          branchDx: 'b1',
          groupDx: val?.data?.icd?.substring(0, 1).toUpperCase(),
          icd: val?.data?.icd,
          icdName: val?.data?.icdName ? val?.data?.icdName.replace(',', '.') : val?.data?.icd,
          orderBy: 1,
        });
      }

      if (
        (val?.data?.icd?.toUpperCase() >= 'I20' && val?.data?.icd?.toUpperCase() <= 'I2599') ||
        (val?.data?.icd?.toUpperCase() >= 'I30' && val?.data?.icd?.toUpperCase() <= 'I5299')
      ) {
        session.TempData.upsert({
          id: val._id,
          branchDx: 'b2',
          groupDx: val?.data?.icd?.substring(0, 1).toUpperCase(),
          icd: val?.data?.icd,
          icdName: val?.data?.icdName ? val?.data?.icdName.replace(',', '.') : val?.data?.icd,
          orderBy: 2,
        });
      }

      if (
        (val?.data?.icd?.toUpperCase() >= 'C00' && val?.data?.icd?.toUpperCase() <= 'C9799') ||
        (val?.data?.icd?.toUpperCase() >= 'D0' && val?.data?.icd?.toUpperCase() <= 'D0999')
      ) {
        session.TempData.upsert({
          id: val._id,
          branchDx: 'b3',
          groupDx: val?.data?.icd?.substring(0, 1).toUpperCase(),
          icd: val?.data?.icd,
          icdName: val?.data?.icdName ? val?.data?.icdName.replace(',', '.') : val?.data?.icd,
          orderBy: 3,
        });
      }

      if (
        (val?.data?.icd?.toUpperCase() >= 'P00' && val?.data?.icd?.toUpperCase() <= 'P9699') ||
        (val?.data?.icd?.toUpperCase() >= 'Q00' && val?.data?.icd?.toUpperCase() <= 'Q9999')
      ) {
        session.TempData.upsert({
          id: val._id,
          branchDx: 'b4',
          groupDx: val?.data?.icd?.substring(0, 1).toUpperCase(),
          icd: val?.data?.icd,
          icdName: val?.data?.icdName ? val?.data?.icdName.replace(',', '.') : val?.data?.icd,
          orderBy: 4,
        });
      }
    }

    let DxBranch = session.Temp2Data.all()
      .filter(Temp2Data => Temp2Data.id !== '')
      .orderBy(Temp2Data => Temp2Data.orderBy, 'asc')
      .toRefArray();

    session.Temp3Data.delete({
      where(record) {
        return record.id !== '';
      },
    });

    for (let dxb of DxBranch) {
      let DiagBC = session.TempData.all()
        .filter(TempData => TempData.id !== '')
        .filter(TempData => TempData.branchDx === dxb.branchDx)
        .orderBy(TempData => TempData.orderBy, 'asc')
        .toRefArray();

      let n1 = 0;

      for (let dxb of DiagBC) {
        n1 = n1 + 1;
        session.Temp3Data.upsert({
          id: dxb.branchDx + dxb.groupDx,
          branchDx: dxb.branchDx,
          groupDx: dxb.groupDx,
          orderBy: n1,
        });
      }
    }

    let dataAll = [];
    let DxCdatas = [];
    let dataTypeDx = [];
    let categories = [];

    let BrDx = session.Temp2Data.all()
      .filter(Temp2Data => Temp2Data.id !== '')
      .orderBy(Temp2Data => Temp2Data.orderBy, 'asc')
      .toRefArray();

    for (let val1 of BrDx) {
      categories.push(val1.branchDxName);

      let DxC = session.TempData.all()
        .filter(TempData => TempData.id !== '')
        .filter(TempData => TempData.branchDx === val1.branchDx)
        .orderBy(TempData => TempData.orderBy, 'asc')
        .toRefArray();

      DxCdatas.push(DxC.length);
    }

    dataAll.push({
      name: 'Total',
      data: DxCdatas,
    });

    let DxTy = session.Temp3Data.all()
      .filter(Temp3Data => Temp3Data.id !== '')
      .orderBy(Temp3Data => Temp3Data.orderBy, 'asc')
      .toRefArray();

    for (let val2 of DxTy) {
      let BrDx = session.Temp2Data.all()
        .filter(Temp2Data => Temp2Data.id !== '')
        .orderBy(Temp2Data => Temp2Data.orderBy, 'asc')
        .toRefArray();

      dataTypeDx = [];

      for (let val3 of BrDx) {
        let DxC = session.TempData.all()
          .filter(TempData => TempData.id !== '')
          .filter(TempData => TempData.branchDx === val3.branchDx)
          .filter(TempData => TempData.groupDx === val2.groupDx)
          .toRefArray();

        dataTypeDx.push(DxC.length);
      }

      dataAll.push({
        name: 'Diag (' + val2.groupDx + '..) ',
        data: dataTypeDx,
      });
    }

    let series = dataAll;

    let options = {
      chart: {
        type: 'bar',
        height: this.state.ReferDiag.length < 10 ? this.state.ReferDiag.length * 80 : this.state.ReferDiag.length * 40,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      title: {
        text:
          `Refer Out 4 สาขา${this.props.filterDeathPatient ? ' (Death)' : ''} | ` +
          ThaiDate(this.props.StartDate) +
          ' - ' +
          ThaiDate(this.props.EndDate),
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' ครั้ง';
          },
        },
        style: {
          fontSize: '14px',
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '14px',
          },
          align: 'left',
          offsetX: 20,
          paddingRight: 50,
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 10,
      },
    };

    this.setState({ ReferDiag: categories });
    this.setState({ OptionsData: options });
    this.setState({ SeriesData: series });
  }

  componentDidMount() {
    if (this.state.ReferDatas !== this.props.ReferOutData) {
      this.setState({ ReferDatas: this.props.ReferOutData });
      setTimeout(() => {
        this.ReferProcess();
      }, 500);
    }
  }

  componentDidUpdate() {
    if (this.state.ReferDatas !== this.props.ReferOutData) {
      this.setState({ ReferDatas: this.props.ReferOutData });

      setTimeout(() => {
        this.ReferProcess();
      }, 500);
    }
  }

  render() {
    return (
      <div>
        {this.state.SeriesData.length > 0 && (
          <Card className='card-refer'>
            <CardContent>
              <div id='chart2' className='refer-chart-box'>
                <ApexCharts
                  options={this.state.OptionsData}
                  series={this.state.SeriesData}
                  type='bar'
                  height={setReportHeight(this.state?.ReferDiag?.length)}
                />
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchRefers: dataRequest => dispatch(fetchRefers(dataRequest)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferOutDiag4BranchChart);
