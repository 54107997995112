import axios from 'axios';
import config from 'react-global-configuration';

export default dataRequest => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const AuthStr = 'Bearer '.concat(dataRequest.idToken);
      let bid = dataRequest.bid;
      let reason = dataRequest.reason;

      let bodyData = {
        bid: bid,
        rejectReason: reason,
      };

      axios
        .put(`${config.get('ROOT_API')}/v1/refer/reject/${bid}`, bodyData, {
          headers: { Authorization: AuthStr },
        })
        .then(res => {
          if (res.status === 200) {
            let data = {
              referReject: {
                referRejectData: res.data.opResult,
              },
              FetchingStatus: false,
            };

            return resolve(data);
          } else {
            let data = {
              referReject: {
                referRejectData: res.data.opResult,
              },
              FetchingStatus: false,
            };

            return resolve(data);
          }
        })
        .catch(err => {
          console.log('AXIOS ERROR: error !');
          // return reject(err.message)
          return reject(err.response);
        });
    }, 200);
  });
};
