import styled from 'styled-components';

const Container = styled.div`
  padding: 16px 16px 24px;
  background-color: #eee;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  .MuiPaper-elevation4 {
    box-shadow: none;
    border: 1px solid #ddd;
  }

  .MuiTypography-h6 {
    font-family: Helvetica, Arial, sans-serif;
    opacity: 1;
    text-anchor: start;
    dominant-baseline: auto;
    font-size: 14px;
    font-weight: 900;
    fill: rgb(55, 61, 63);
  }

  .MUIDataTableBodyCell {
    font-family: Helvetica, Arial, sans-serif;
    text-anchor: start;
    dominant-baseline: auto;
    font-size: 14px;
    font-weight: 400;
    fill: rgb(55, 61, 63);
  }

  .inner-table {
    .MuiTableCell-root {
      padding: 4px;
      font-size: 0.7rem;
    }

    .MuiTableCell-footer {
      border-style: none;
    }
  }
`;

export { Container };
