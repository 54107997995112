import { APPDATA, APPDATA_FULFILLED } from '../constants/appData';
import BillingsCharge from '../api/Billings/BillingsCharge';
import SubmitCharge from '../api/Billings/SubmitCharge';
import PayerFinishBilling from '../api/Billings/PayerFinishBilling';
import PayerFinishReviewingBill from '../api/Billings/PayerFinishReviewingBill';
import BillingItemsByPatient from '../api/Billings/BillingItemsByPatient';
import UpdateVisitStatus from '../api/Billings/UpdateVisitStatus';
import FetchHospMain from '../api/Billings/FetchHospMain';

export function fetchHospMain({ idToken, hospCode }) {
  return {
    type: APPDATA,
    payload: FetchHospMain({ idToken, hospCode }),
  };
}

export function fetchBillingsCharge({ idToken, provider, payer, year }) {
  return {
    type: APPDATA,
    payload: BillingsCharge({ idToken, provider, payer, year }),
  };
}

export function changeHospital(selectedHospmain) {
  return {
    type: APPDATA,
    payload: {
      selectedHospmain,
    },
  };
}

export function openChargeModal(category) {
  return {
    type: APPDATA_FULFILLED,
    payload: {
      chargeModal: category,
      alert: { show: false, msg: '', type: null },
    },
  };
}

export function closeChargeModal() {
  return {
    type: APPDATA_FULFILLED,
    payload: {
      chargeModal: false,
      alert: { show: false, msg: '', type: null },
    },
  };
}

export function confirmCharged(dataRequest) {
  return {
    type: APPDATA,
    payload: SubmitCharge(dataRequest),
  };
}

export function finishCharged(dataRequest) {
  return {
    type: APPDATA,
    payload: PayerFinishBilling(dataRequest),
  };
}

export function finishReviewCharged(dataRequest) {
  return {
    type: APPDATA,
    payload: PayerFinishReviewingBill(dataRequest),
  };
}

export function fetchBillingItemsByPatient(dataRequest) {
  return {
    type: APPDATA,
    payload: BillingItemsByPatient(dataRequest),
  };
}

export function updateVisitStatus({ idToken, cid, vn, statusToUpdate, provider, payer, year, categoryId }) {
  return {
    type: APPDATA,
    payload: UpdateVisitStatus({
      idToken,
      cid,
      vn,
      statusToUpdate,
      provider,
      payer,
      year,
      categoryId,
    }),
  };
}
