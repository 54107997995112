import axios from 'axios';
import config from 'react-global-configuration';

export const FetchHospitalList = ({
  idToken,
  page = 1,
  limit = 20,
  sortColumn = '',
  sortType = 0,
  filterText = '',
  filterList = [],
}) => {
  let dispatch = {};
  const url = `${config.get('ROOT_API')}/v1/lists/hospital`;

  let filter = {};
  if (filterList.length) {
    if (filterList[0].length) {
      filter.hospCode = filterList[0];
    }

    if (filterList[1].length) {
      filter.hospName = filterList[1];
    }
  }

  return axios
    .get(url, {
      headers: { authorization: `Bearer ${idToken}` },
      params: { page, limit, sortColumn, sortType, filterText, filter },
    })
    .then(res => {
      dispatch = {
        hospitalList: res.data.result,
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(err => {
      console.log('fetch hospital list axios error');

      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
