import axios from 'axios';
import config from 'react-global-configuration';

export default dataRequest => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const AuthStr = 'Bearer '.concat(dataRequest.idToken);
      let hospCode = dataRequest.hospCode;
      axios
        .get(`${config.get('ROOT_API')}/v1/billing/categories/${hospCode}`, {
          headers: { Authorization: AuthStr },
        })
        .then(res => {
          if (res.status === 200) {
            let data = {
              categories: res.data.data,
              FetchingStatus: false,
            };

            return resolve(data);
          } else {
            let data = {
              categories: {
                categoriesData: res.data,
              },
              FetchingStatus: false,
            };

            return resolve(data);
          }
        })
        .catch(err => {
          console.log('AXIOS ERROR: error !');
          // return reject(err.message)
          return reject(err.response);
        });
    }, 200);
  });
};
