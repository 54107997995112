import axios from 'axios';
import config from 'react-global-configuration';

export default async body => {
  const { idToken, category, hospCode } = body;
  try {
    const result = await axios.get(`${config.get('ROOT_API')}/v1/billingRules/rules/${hospCode}/${category}`, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    return result.data;
  } catch (e) {
    throw new Error(e);
  }
};
