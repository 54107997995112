import axios from 'axios';
import config from 'react-global-configuration';
import UploadFile from './UploadFile';

const AttachFiles = async ({ idToken, bid, attachments, existsAttachments }) => {
  let dispatch = {};
  let tmpAttachment = existsAttachments || [];
  const url = `${config.get('ROOT_API')}/v1/refer/attachfiles`;

  try {
    if (attachments.length) {
      for (let i = 0; i < attachments.length; i++)
        tmpAttachment.push(await UploadFile({ idToken, file: attachments[i] }));
    }

    const body = {
      bid,
      attachments: tmpAttachment,
    };

    return axios
      .put(url, body, {
        headers: { authorization: `Bearer ${idToken}` },
      })
      .then(res => {
        dispatch = {
          FetchingStatus: false,
        };
        return dispatch;
      })
      .catch(err => {
        console.log('attach files axios error');
        dispatch = {
          FetchingStatus: false,
        };
        return dispatch;
      });
  } catch (error) {
    dispatch = {
      FetchingStatus: false,
    };
    return dispatch;
  }
};

export default AttachFiles;
