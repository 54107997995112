import axios from 'axios';
import config from 'react-global-configuration';

const DeleteAttachment = ({ idToken, id }) => {
  let dispatch = {};
  const url = `${config.get('ROOT_API')}/v1/refer/deleteattachment/${id}`;

  return axios
    .put(url, {}, { headers: { Authorization: `Bearer ${idToken}` } })
    .then(res => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(err => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};

export default DeleteAttachment;
