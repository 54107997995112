import React, { Component } from 'react';
import { connect } from 'react-redux';
import ApexCharts from 'react-apexcharts';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchRefers } from '../../actions/ReferDatas';

// functions
import { ThaiDate } from '../../functions/FuncDateTimes';
import { setReportHeight } from '../../functions/Report';

// mui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const session = orm.session();

class ReferBackDiagQtyChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ReferDatas: [],
      ReferAcceptData: [],
      ReferReject: [],
      ReferDiag: [],
      OptionsData: {},
      SeriesData: [],
    };
    this.ReferProcess = this.ReferProcess.bind(this);
  }

  ReferProcess() {
    const referDb = this.state.ReferDatas;

    session.ReferBackDatas.delete({
      where(record) {
        return record._id !== '';
      },
    });

    session.TempData.delete({
      where(record) {
        return record.id !== '';
      },
    });

    session.Temp2Data.delete({
      where(record) {
        return record.id !== '';
      },
    });

    for (var val of referDb) {
      session.ReferBackDatas.upsert(val);

      session.TempData.upsert({
        id: val?.data?.icd,
        icdName: val?.data?.icdName ? val?.data?.icdName.replace(',', '.') : val?.data?.icd,
      });

      session.Temp2Data.upsert({
        id: val?.data?.refername,
        referName: val?.data?.refername,
      });
    }

    // Refer Diag

    let ReferDiagData2 = session.TempData.all()
      .filter(TempData => TempData.id !== '')
      .toRefArray();

    let ReferName2 = session.Temp2Data.all()
      .filter(Temp2Data => Temp2Data.id !== '')
      .orderBy(Temp2Data => Temp2Data.referName, 'asc')
      .toRefArray();

    session.TempData.delete({
      where(record) {
        return record.id !== '';
      },
    });

    let nn = 0;
    let qty = [];

    for (var valDiag of ReferDiagData2) {
      nn = nn + 1;
      let icd = valDiag.id;
      if (nn <= 20) {
        qty = session.ReferBackDatas.all()
          .filter(ReferBackDatas => ReferBackDatas?.data?.icd === icd)
          .toRefArray();

        session.TempData.upsert({
          id: valDiag.id,
          icd: valDiag.id,
          icdName: valDiag.icdName,
          qty: qty.length,
        });
      }
    }

    let ReferDiagDataQry2 = session.TempData.all()
      .filter(TempData => TempData.id !== '')
      .orderBy(TempData => TempData.qty, 'desc')
      .toRefArray();

    let categories = [];
    let datas = [];

    session.Temp3Data.delete({
      where(record) {
        return record.id !== '';
      },
    });

    let number = 0;

    for (let DxQty of ReferDiagDataQry2) {
      number = number + 1;
      categories.push(number + ') ' + DxQty.icd + ' ' + DxQty.icdName);
      datas.push(DxQty.qty);

      for (let ReferNameV of ReferName2) {
        let qty22 = session.ReferBackDatas.all()
          .filter(ReferBackDatas => ReferBackDatas?.data?.icd === DxQty.icd)
          .filter(ReferBackDatas => ReferBackDatas?.data?.refername === ReferNameV.referName)
          .toRefArray();

        session.Temp3Data.upsert({
          id: DxQty.icd + ReferNameV.referName,
          icd: DxQty.icd,
          qty: DxQty.qty,
          referName: ReferNameV.referName,
          referNameQty: qty22.length,
        });
      }
    }

    let dataAll = [];
    let dataTypeDx = [];

    dataAll.push({
      name: 'รวมจำนวน',
      data: datas,
    });

    for (let ReferNameV2 of ReferName2) {
      let ReferNameData = session.Temp3Data.all()
        .filter(Temp3Data => Temp3Data.referName === ReferNameV2.referName)
        .orderBy(Temp3Data => Temp3Data.qty, 'desc')
        .toRefArray();

      dataTypeDx = [];

      for (let rfv of ReferNameData) {
        dataTypeDx.push(rfv.referNameQty);
      }

      dataAll.push({
        name: ReferNameV2.referName,
        data: dataTypeDx,
      });
    }

    let series = dataAll;

    let options = {
      chart: {
        type: 'bar',
        height: this.state.ReferDiag.length < 10 ? this.state.ReferDiag.length * 80 : this.state.ReferDiag.length * 40,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      title: {
        text:
          `Top 20 Diag Refer Back${this.props.filterDeathPatient ? ' (Death)' : ''} | ` +
          ThaiDate(this.props.StartDate) +
          ' - ' +
          ThaiDate(this.props.EndDate),
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' ครั้ง';
          },
        },
        style: {
          fontSize: '14px',
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '14px',
          },
          align: 'left',
          offsetX: 20,
          paddingRight: 50,
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 10,
      },
    };

    // console.log(series)

    this.setState({ ReferDiag: ReferDiagDataQry2 });

    this.setState({ OptionsData: options });
    this.setState({ SeriesData: series });
  }

  componentDidMount() {
    if (this.state.ReferDatas !== this.props.ReferBackData) {
      this.setState({ ReferDatas: this.props.ReferBackData });
      setTimeout(() => {
        this.ReferProcess();
      }, 500);
    }
  }

  componentDidUpdate() {
    if (this.state.ReferDatas !== this.props.ReferBackData) {
      this.setState({ ReferDatas: this.props.ReferBackData });

      setTimeout(() => {
        this.ReferProcess();
      }, 500);
    }
  }

  render() {
    return (
      <div>
        {this.state.SeriesData.length > 0 && (
          <Card className='card-refer'>
            <CardContent>
              <div id='chart2' className='refer-chart-box'>
                <ApexCharts
                  options={this.state.OptionsData}
                  series={this.state.SeriesData}
                  type='bar'
                  height={setReportHeight(this.state?.ReferDiag?.length)}
                />
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchRefers: dataRequest => dispatch(fetchRefers(dataRequest)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferBackDiagQtyChart);
