import styled from 'styled-components';

const Container = styled.div`
  .hide {
    display: none;
  }

  .refer-4-branch-container {
    z-index: 999;
  }

  .refer-4-branch-view {
    display: flex;
    justify-content: flex-end;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #eee;
    padding-right: 12px;
    padding-top: 12px;
  }

  .refer-4-branch-chart {
    .MuiPaper-elevation1 {
      box-shadow: none;
    }

    .MuiPaper-rounded {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
`;

export { Container };
