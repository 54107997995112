export const SUPERADMIN = 'SUPERADMIN';
export const ADMIN = 'ADMIN';
export const REFER_MANAGER = 'REFER_MANAGER';
export const REFER_USER = 'REFER_USER';
export const REPORT_USER = 'REPORT_USER';
export const AMPHOE_ADMIN = 'AMPHOE_ADMIN';

export const roles = [
  { label: 'Provincial Health Office', value: SUPERADMIN },
  { label: 'Amphoe Health Office', value: AMPHOE_ADMIN },
  { label: 'Admin', value: ADMIN },
  { label: 'Refer Manager', value: REFER_MANAGER },
  { label: 'Refer User', value: REFER_USER },
  { label: 'Report User', value: REPORT_USER },
];
