import axios from 'axios';
import config from 'react-global-configuration';

export default async body => {
  const { idToken, hospCode, category } = body;
  try {
    const result = await axios.delete(`${config.get('ROOT_API')}/v1/billing/categories/${hospCode}/${category}`, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    return result;
  } catch (e) {
    throw new Error(e);
  }
};
