import { APPDATA } from '../constants/appData';
import FetchUsers from '../api/UserManagement/FetchUsers';
import AddUser from '../api/UserManagement/AddUser';
import EditUser from '../api/UserManagement/EditUser';
import { FetchHospitalList } from '../api/UserManagement/FetchHospitalList';

export function fetchUsers({ idToken, page, limit, sortColumn, sortType, filterText, filterList }) {
  return {
    type: APPDATA,
    payload: FetchUsers({ idToken, page, limit, sortColumn, sortType, filterText, filterList }),
  };
}

export function addUser({ idToken, body }) {
  return {
    type: APPDATA,
    payload: AddUser({ idToken, body }),
  };
}

export function editUser(req) {
  return {
    type: APPDATA,
    payload: EditUser(req),
  };
}

export function fetchHospitalList({ idToken, page, limit, sortColumn, sortType, filterText, filterList }) {
  return {
    type: APPDATA,
    payload: FetchHospitalList({ idToken, page, limit, sortColumn, sortType, filterText, filterList }),
  };
}
