import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

// constants
import { REFER_IN, REFER_BACK } from '../../../../constants/Refers/referType';

// styles
import { DialogTitle, useStyles } from './ReferAcceptDialog.style';

// mui
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { Grid, TextField } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';

export default function ReferAcceptDialog(props) {
  const classes = useStyles();
  const { handleSubmit, control } = useForm();

  const [referType, setReferType] = useState(false);

  const onSubmit = referData => {
    let appointmentInfo = {};

    if (referType === REFER_IN) {
      appointmentInfo = referData;
    }

    props.onSubmit(appointmentInfo);
  };

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={props.open}
      onClose={() => props.onClose()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='refer-dialog-accept'
    >
      <form onSubmit={handleSubmit(e => onSubmit(e))}>
        <DialogTitle id='alert-dialog-title' onClose={() => props.onClose()}>
          <strong>ยืนยันการรับ Refer</strong>
        </DialogTitle>
        <DialogContent>
          {props.cid && <DialogContentText id='alert-dialog-description'>CID : {props.cid}</DialogContentText>}
          {props.ptname && (
            <DialogContentText id='alert-dialog-description'>ผู้ป่วย : {props.ptname}</DialogContentText>
          )}
          {props.hospName && (
            <DialogContentText id='alert-dialog-description'>จาก : {props.hospName}</DialogContentText>
          )}
          <Grid container spacing={1}>
            <Grid item sm={9}>
              <div className={classes.referActionContainer}>
                <Controller
                  as={
                    <DateTimePicker
                      fullWidth
                      format='DD/MM/YYYY : hh:mm A'
                      margin='dense'
                      label='วัน / เวลา นัดหมาย'
                      inputVariant='outlined'
                    />
                  }
                  defaultValue={new Date()}
                  control={control}
                  name='appointmentDateTime'
                />

                <Controller
                  as={
                    <TextField
                      fullWidth
                      required={referType === REFER_IN}
                      margin='dense'
                      label='จุดนัดหมาย'
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='appointmentPoint'
                />

                <Controller
                  as={
                    <TextField
                      fullWidth
                      multiline
                      margin='dense'
                      label='หมายเหตุ'
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='appointmentNotes'
                />

                <Button
                  id={REFER_IN}
                  type='submit'
                  size='small'
                  color='primary'
                  variant='contained'
                  className={classes.referBtn}
                  onClick={e => setReferType(e.currentTarget.id)}
                >
                  รับ Refer
                </Button>
              </div>
            </Grid>
            <Grid item sm={3} className={classes.referBackBtn}>
              <div>
                <Button
                  id={REFER_BACK}
                  type='submit'
                  size='small'
                  color='primary'
                  variant='contained'
                  onClick={e => setReferType(e.currentTarget.id)}
                >
                  รับ Refer Back
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
}
