import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';

// mui
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const session = orm.session();

function PtScreen({ bid, appData }) {
  const [visitData, setvisitData] = useState(null);
  const [bidData, setDataBid] = useState(false);

  const setDataBidFc = bid => {
    setDataBid(bid);
  };

  const setvisitDataFc = bid => {
    const visits = appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.bid === bid;
      },
    });

    for (var val of visits) {
      session.Visits.upsert(val);
    }

    let visitsData = session.Visits.all()
      .filter(Visits => Visits.bid === bid)
      .toRefArray();

    setvisitData({ visitsData });
  };

  useEffect(() => {
    if (bid !== bidData) {
      setDataBidFc(bid);
      setvisitDataFc(bid);
    }
  });

  return (
    <div>
      {visitData && (
        <div>
          {visitData.visitsData.map((items, index) => (
            <div key={index}>
              {items.visitVital && (
                <Card variant='outlined' className='visit-detail-card'>
                  <Row>
                    <Col lg={12}>
                      <h5>Screen</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Grid container spacing={1} className='font-size-12px'>
                        <Grid item xs={3}>
                          TEMP: {items.visitVital.temperature} °C
                        </Grid>
                        <Grid item xs={3}>
                          PULSE: {items.visitVital.pulse} /min.
                        </Grid>
                        <Grid item xs={3}>
                          RR: {items.visitVital.rr} /min.
                        </Grid>
                        <Grid item xs={3}>
                          BP: {items.visitVital.bps} / {items.visitVital.bpd} mmHg
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className='font-size-12px'>
                        <Grid item xs={3}>
                          FBS: {items.visitVital.fbs}
                        </Grid>
                        <Grid item xs={3}>
                          WAIST: {items.visitVital.waist}
                        </Grid>
                        <Grid item xs={3}>
                          HEIGHT: {items.visitVital.height}
                        </Grid>
                        <Grid item xs={3}>
                          BW: {items.visitVital.bw}
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className='font-size-14px'>
                        <Grid item xs={12}>
                          CC :
                          {items.visitVital.cc && (
                            <div style={{ paddingLeft: '8px' }}>
                              {items.visitVital.cc.split('\r').map((item2, index2) => (
                                <div key={index2}> {item2}</div>
                              ))}
                            </div>
                          )}
                        </Grid>
                      </Grid>

                      {items.visitVital.hpi && (
                        <Grid container spacing={1} className='font-size-14px'>
                          <Grid item xs={12}>
                            HPI :
                            {items.visitVital.hpi && (
                              <div style={{ paddingLeft: '8px' }}>
                                {items.visitVital.hpi.split('\r').map((item2, index2) => (
                                  <div key={index2}> {item2}</div>
                                ))}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      )}

                      {items.visitVital.pmh && (
                        <Grid container spacing={1} className='font-size-14px'>
                          <Grid item xs={12}>
                            PMH :
                            {items.visitVital.pmh && (
                              <div style={{ paddingLeft: '8px' }}>
                                {items.visitVital.pmh.split('\r').map((item2, index2) => (
                                  <div key={index2}> {item2}</div>
                                ))}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      )}

                      {items.visitVital.fh && (
                        <Grid container spacing={1} className='font-size-14px'>
                          <Grid item xs={12}>
                            FH :
                            {items.visitVital.fh && (
                              <div style={{ paddingLeft: '8px' }}>
                                {items.visitVital.fh.split('\r').map((item2, index2) => (
                                  <div key={index2}> {item2}</div>
                                ))}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      )}

                      {items.visitVital.sh && (
                        <Grid container spacing={1} className='font-size-14px'>
                          <Grid item xs={12}>
                            SH :
                            {items.visitVital.sh && (
                              <div style={{ paddingLeft: '8px' }}>
                                {items.visitVital.sh.split('\r').map((item2, index2) => (
                                  <div key={index2}> {item2}</div>
                                ))}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      )}

                      {items.visitVital.ros && (
                        <Grid container spacing={1} className='font-size-14px'>
                          <Grid item xs={12}>
                            ROS :
                            {items.visitVital.ros && (
                              <div style={{ paddingLeft: '8px' }}>
                                {items.visitVital.ros.split('\r').map((item2, index2) => (
                                  <div key={index2}> {item2}</div>
                                ))}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Col>
                  </Row>
                </Card>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PtScreen));
