import React from 'react';

// styles
import { DialogTitle } from './ReferRejectDialog.style';

// mui
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export default function ReferRejectDialog(props) {
  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={props.open}
      onClose={() => props.onClose()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='refer-dialog-reject'
    >
      <DialogTitle id='alert-dialog-title' onClose={() => props.onClose()}>
        <strong>ยืนยันการปฏิเสธ Refer</strong>
      </DialogTitle>
      <DialogContent>
        {props.cid && <DialogContentText id='alert-dialog-description'>CID : {props.cid}</DialogContentText>}
        {props.ptname && <DialogContentText id='alert-dialog-description'>ผู้ป่วย : {props.ptname}</DialogContentText>}
        {props.hospName && <DialogContentText id='alert-dialog-description'>จาก : {props.hospName}</DialogContentText>}

        <DialogContentText>
          <TextField
            fullWidth
            multiline
            id='outlined-multiline-static'
            label='เหตุผล'
            rows={3}
            value={props.reason}
            variant='outlined'
            onChange={e => props.onChange(e)}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onSubmit()} color='primary' variant='contained'>
          ยืนยันการปฏิเสธ Refer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
