import axios from 'axios';
import config from 'react-global-configuration';
import _ from 'lodash';

// constants
import { REFER_IN } from '../../constants/Refers/referType';

export default dataRequest => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const AuthStr = 'Bearer '.concat(dataRequest.idToken);
      let startDate = dataRequest.startDate;
      let endDate = dataRequest.endDate;
      let limit = dataRequest.limit;
      let cid = dataRequest.cid;
      let name = dataRequest.name;
      let type = dataRequest.type;
      let startDateSet = startDate + ' 00:00:00';
      let endDateSet = endDate + ' 23:59:59';
      let groupBy = type === REFER_IN ? 'data.toHospCode' : 'data.fromHospCode';
      let filterDeathPatient = dataRequest.filterDeathPatient || false;

      let cid_parm = '';
      if (cid !== '' && cid !== undefined && cid) {
        cid_parm = '&cid=' + cid;
      } else {
        cid_parm = '';
      }

      let name_parm = '';
      if (name !== '' && name !== undefined && name) {
        name_parm = '&name=' + name;
      } else {
        name_parm = '';
      }

      axios
        .get(
          `${config.get(
            'ROOT_API'
          )}/v1/refer/chw?startDate=${startDateSet}&endDate=${endDateSet}&type=${type}&filterDeathPatient=${filterDeathPatient}&limit=${limit}${cid_parm}${name_parm}`,
          {
            headers: { Authorization: AuthStr },
          }
        )
        .then(res => {
          if (res.status === 200) {
            let data = {
              emrData: {
                referDatas: Object.values(_(res.data.result.referrals).groupBy(groupBy).value()),
              },
              FetchingStatus: false,
            };

            return resolve(data);
          } else {
            let data = {
              emrData: {
                referDatas: Object.values(_(res.data.result.referrals).groupBy(groupBy).value()),
              },
              FetchingStatus: false,
            };

            return resolve(data);
          }
        })
        .catch(err => {
          console.log('AXIOS ERROR: error !');
          // return reject(err.message)
          return reject(err.response);
        });
    }, 200);
  });
};
