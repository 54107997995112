// constants
import { COMPLETE, PENDING, REJECTED } from '../constants/Refers/referralStatus';
import { REFER_IN, REFER_OUT } from '../constants/Refers/referType';

const getReferType = ({ hospCode, fromHospCode }) => {
  if (!hospCode || !fromHospCode) return false;

  if (hospCode === fromHospCode) return REFER_OUT;
  else return REFER_IN;
};

const getReferColor = ({ referral, pendingBackgroundShow = false }) => {
  let referralClass = '';

  if (referral?.ptDeceasedBoolean) {
    referralClass = 'referrals-card-deceased';
  } else {
    if (referral?.status?.toUpperCase() === '' || referral?.status?.toUpperCase() === PENDING) {
      if (referral?.data?.incomplete) {
        referralClass = 'referrals-card-incomplete';
      } else {
        referralClass = pendingBackgroundShow ? 'referrals-card' : 'card-refer';
      }
    } else if (referral?.status?.toUpperCase() === COMPLETE) {
      referralClass = 'referrals-card-complete';
    } else if (referral?.status?.toUpperCase() === REJECTED) {
      referralClass = 'referrals-card-rejected';
    }
  }

  return referralClass;
};

export { getReferType, getReferColor };
