import axios from 'axios';
import config from 'react-global-configuration';

const BillingsCharge = ({ idToken, provider, payer, year }) => {
  let dispatch = {};
  const url = `${config.get('ROOT_API')}/v1/billingsummary?provider=${provider}&payer=${payer}&year=${year}`;

  return axios
    .get(url, { headers: { Authorization: `Bearer ${idToken}` } })
    .then(res => {
      dispatch = {
        billings: res.data.result ? res.data.result.data : [],
        FetchingStatus: false,
        provider,
      };

      return dispatch;
    })
    .catch(err => {
      dispatch = {
        FetchingStatus: false,
      };
      return dispatch;
    });
};

export default BillingsCharge;

