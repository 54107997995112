import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Parser } from 'json2csv';
import axios from 'axios';
import config from 'react-global-configuration';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { TableRow, TableCell, Container } from './styles';
import { fetchCategories } from '../../actions/Categories';
import DeleteCategory from '../../api/Billings/DeleteCategory';

const Settings = props => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const { appData } = useSelector(state => state);
  const { idToken, hospCode, categories } = appData;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchCategories({ idToken, hospCode }));
    };

    fetchData();
  }, [dispatch, idToken, hospCode]);

  const handleDelete = async () => {
    setLoading(true);
    await DeleteCategory({ idToken, hospCode, category: modal.code });
    fetchCategories({ idToken, hospCode });
    setLoading(false);
    setModal(false);
  };

  const exportBillingRules = async () => {
    const json2csvParser = new Parser();
    const result = await axios.get(`${config.get('ROOT_API')}/v1/billingrules/exportRules/${hospCode}`, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    console.log(result.data.categories.length > 0 ? 'true' : 'false');
    const csv = json2csvParser.parse(result?.data.categories.length > 0 ? result.data.categories : { null: 'null' });
    const url = window.URL.createObjectURL(new Blob([csv]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'rules.csv');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Container>
      <div className='header'>
        <h4>กลุ่มสิทธิ์เรียกเก็บ</h4>
        <div>
          <Button onClick={exportBillingRules} className='export' variant='contained' size='small'>
            ดาวน์โหลดรายการยาและบริการ
          </Button>
          <Button
            onClick={() => history.push('/settings/edit-category')}
            variant='contained'
            color='primary'
            size='small'
          >
            เพิ่มกลุ่มสิทธิ์
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell>รหัส</TableCell> */}
              <TableCell>ชื่อสิทธิ์หลัก</TableCell>
              <TableCell>รหัสสิทธิ์ pttype</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories &&
              categories.map(row => (
                <TableRow key={row.code}>
                  {/* <TableCell component="th" scope="row">
                                    {row.code}
                                </TableCell> */}
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.members && row.members.join(', ')}</TableCell>
                  <TableCell align='right'>
                    <span className='edit-cate'>
                      <Button
                        onClick={() =>
                          history.push(`/settings/edit-category/${row.code}`, {
                            category: row,
                          })
                        }
                        variant='contained'
                        size='small'
                      >
                        แก้ไข
                      </Button>
                    </span>
                    <Button
                      onClick={() => setModal({ code: row.code, name: row.name })}
                      variant='contained'
                      size='small'
                      color='secondary'
                    >
                      ลบ
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={modal} onClose={() => setModal(false)}>
        <DialogTitle>ลบรายการ {modal.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>ยืนยันที่จะลบ ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={() => setModal(false)} color='primary'>
            ยกเลิก
          </Button>
          <Button disabled={loading} onClick={e => handleDelete({idToken, hospCode, categoryId: modal._id})} color='primary'>
            ยืนยัน
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
    </Container>
  );
};

export default Settings;
