import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { write, utils } from 'xlsx';
import { forEach, reduce } from 'lodash';

// functions
import { ThaiDate } from '../../../functions/FuncDateTimes';
import { CategoryType, createBranchByIcd, innerTableDataByCategory } from '../../../functions/Report';

// styles
import { Container } from './ReferDiag4BranchTable.style';

// types
import { IReferral, ReferralType } from '../../../types/referral';
import { IOuterTableDataSet } from '../../../types/report';

// mui
import MUIDataTable, { Responsive, SelectableRows } from 'mui-datatables';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { IconButton, Tooltip } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

interface IProps {
  StartDate: Date;
  EndDate: Date;
  referDatas: IReferral[];
  referType: ReferralType;
}

interface IBranchs {
  id?: string;
  branchDx?: string;
  branchDxName?: string;
  groupDx?: string;
  icd?: string;
  icdName?: string;
  orderBy?: number;
}

const fourBranchs = [
  {
    id: 'b1',
    branchDx: 'b1',
    branchDxName: '1.อุบัติเหตุ',
    orderBy: 1,
  },
  {
    id: 'b2',
    branchDx: 'b2',
    branchDxName: '2.หัวใจ',
    orderBy: 2,
  },
  {
    id: 'b3',
    branchDx: 'b3',
    branchDxName: '3.มะเร็ง',
    orderBy: 3,
  },
  {
    id: 'b4',
    branchDx: 'b4',
    branchDxName: '4.ทารกแรกเกิด',
    orderBy: 4,
  },
];

export default function ReferDiag4BranchTable({ referDatas, StartDate, EndDate, referType }: IProps) {
  const [outerTableData, setOuterTableData] = useState<IOuterTableDataSet[]>(defaultProps.outerTableData);

  useEffect(() => {
    processData({ referDatas });
  }, [referDatas]);

  const processData = ({ referDatas }: { referDatas: IReferral[] }) => {
    let branchs: IBranchs[] = [];
    let filteredBranchs: IBranchs[] = [];

    // create 4 branchs
    referDatas.map(item => branchs.push(createBranchByIcd({ referData: item })));

    for (let dxb of fourBranchs) {
      let DiagBC: IBranchs[] = branchs
        .filter((TempData: { id?: string }) => TempData.id !== '')
        .filter((TempData: { branchDx?: string }) => TempData.branchDx === dxb.branchDx)
        .sort();

      let n1 = 0;

      for (let dxb of DiagBC) {
        n1 = n1 + 1;

        const createdBranch = filteredBranchs.find(item => item.groupDx === dxb.groupDx);

        // update or insert branchs
        if (createdBranch?.id) {
          const indexOfUpdateBranch = filteredBranchs.indexOf(createdBranch);

          filteredBranchs[indexOfUpdateBranch].orderBy = n1;
        } else {
          filteredBranchs.push({
            id: dxb.branchDx && dxb.groupDx ? dxb.branchDx + dxb.groupDx : '',
            branchDx: dxb.branchDx,
            groupDx: dxb.groupDx,
            orderBy: n1,
          });
        }
      }
    }

    let dataAll = [];
    let DxCdatas = [];
    let dataTypeDx = [];
    let categories = [];

    for (let val1 of fourBranchs) {
      categories.push(val1.branchDxName);

      let DxC = branchs
        .filter(TempData => TempData.id !== '')
        .filter(TempData => TempData.branchDx === val1.branchDx)
        .sort();
      DxCdatas.push(DxC.length);
    }

    dataAll.push({
      name: 'Total',
      data: DxCdatas,
    });

    let DxTy = filteredBranchs.filter(Temp3Data => Temp3Data.id !== '').sort();

    for (let val2 of DxTy) {
      let BrDx = fourBranchs.filter(Temp2Data => Temp2Data.id !== '').sort();

      dataTypeDx = [];

      for (let val3 of BrDx) {
        let DxC = branchs
          .filter(TempData => TempData.id !== '')
          .filter(TempData => TempData.branchDx === val3.branchDx)
          .filter(TempData => TempData.groupDx === val2.groupDx);

        dataTypeDx.push(DxC.length);
      }

      dataAll.push({
        name: 'Diag (' + val2.groupDx + '..) ',
        data: dataTypeDx,
      });
    }

    const objTotal = dataAll.find(item => (item.name = 'Total')) || { name: '', data: [0, 0, 0, 0] };

    setOuterTableData(createOuterTableData({ objTotal }));
  };

  const createOuterTableData = ({ objTotal }: { objTotal: { name: string; data: number[] } }) => {
    let dataSet: IOuterTableDataSet[] = [];

    if (objTotal.name) {
      fourBranchs.map((item, index) => dataSet.push({ branchName: item.branchDxName, amount: objTotal.data[index] }));
    }

    return dataSet;
  };

  const renderExpandableRow = (rowData: string[]) => {
    const category = fourBranchs.find(item => item.branchDxName === rowData[0])?.id as CategoryType;
    const result = innerTableDataByCategory({ referDatas, category });

    const onDownload = ({ columns, values }: { columns: any; values: any }) => {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';

      const json = reduce(
        values,
        (result: {}[], val: { data: any }) => {
          const temp: any[] = [];
          forEach(val.data, (v: any, idx: string | number) => {
            temp[columns[idx].label] = v;
          });
          result.push(temp);
          return result;
        },
        []
      );

      const strStartDate = new Date(StartDate).toLocaleDateString('en-GB');
      const strEndDate = new Date(EndDate).toLocaleDateString('en-GB');

      const fileName = rowData[0].substr(2) + '_' + strStartDate + '_' + strEndDate;
      const ws = utils.json_to_sheet(json);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);
    };

    const innerColumns = [
      {
        name: 'fromHospName',
        label: 'สถานพยาบาลส่งต่อ',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'ptname',
        label: 'ชื่อผู้ป่วย',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'ptAge',
        label: 'อายุ',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'ptSexName',
        label: 'เพศ',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'referPoint',
        label: 'จุดรีเฟอร์',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'toHospName',
        label: 'สถานพยาบาลรับส่ง',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'icd',
        label: 'โรคที่ส่งต่อ',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'refername',
        label: 'สาเหตุที่ต้องส่งต่อ',
        options: {
          filter: false,
          sort: false,
        },
      },
    ];

    const innerOptions = {
      responsive: 'standard' as Responsive,
      filter: false,
      print: false,
      search: false,
      viewColumns: false,
      expandableRowsHeader: false,
      download: false,
      elevation: 0,
      count: result.length,
      rowsPerPage: 5,
      rowsPerPageOptions: [5],
      selectableRows: 'none' as SelectableRows,
      customToolbar: (data: any) => {
        return (
          <Tooltip title={'Download Excel'}>
            <IconButton onClick={() => onDownload({ columns: innerColumns, values: data.displayData })}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        );
      },
    };

    return (
      <TableRow className='inner-table'>
        <TableCell colSpan={1} />
        <TableCell colSpan={rowData.length}>
          <MUIDataTable title='' data={result} columns={innerColumns} options={innerOptions} />
        </TableCell>
      </TableRow>
    );
  };

  const getTitle = ({ referType, StartDate, EndDate }: { referType: ReferralType; StartDate: Date; EndDate: Date }) => {
    let strReferType = '';

    switch (referType) {
      case ReferralType.REFER_IN:
        strReferType = 'Refer In 4 สาขา | ';
        break;
      case ReferralType.REFER_OUT:
        strReferType = 'Refer Out 4 สาขา | ';
        break;
      case ReferralType.REFER_BACK:
        strReferType = 'Refer Back 4 สาขา | ';
        break;

      default:
        break;
    }

    return strReferType + ThaiDate(StartDate) + ' - ' + ThaiDate(EndDate);
  };

  const columns = [
    {
      name: 'branchName',
      label: 'สาขา',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'amount',
      label: 'จำนวน (คน)',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    pagination: false,
    expandableRows: true,
    expandableRowsHeader: false,
    selectableRows: 'none' as SelectableRows,
    responsive: 'standard' as Responsive,
    renderExpandableRow,
  };

  return (
    <Container>
      <MUIDataTable
        title={getTitle({ referType, StartDate, EndDate })}
        data={outerTableData}
        columns={columns}
        options={options}
      />
    </Container>
  );
}

const defaultProps = {
  outerTableData: [
    {
      branchName: '',
      amount: 0,
    },
  ],
};
