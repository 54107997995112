import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

// functions
import { ThaiDateTime } from '../../functions/FuncDateTimes';

// mui
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const session = orm.session();

function PtLab({ bid, an, cid, dataType, appData, printClick }) {
  const [visitData, setvisitData] = useState(null);
  const [bidData, setDataBid] = useState(false);
  const [pageData, setPage] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (bid !== bidData) {
      setDataBidFc(bid);
      setvisitDataFc(bid);
    }
  });

  useEffect(() => {
    printClick ? setIsExpanded(true) : setIsExpanded(false);
  }, [printClick]);

  const setDataBidFc = bid => {
    setDataBid(bid);
  };

  const setvisitDataFc = bid => {
    const visits = appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.bid === bid;
      },
    });

    session.Labs.delete({
      where(record) {
        return record.cid === cid;
      },
    });

    for (var val of visits) {
      session.Visits.upsert(val);
    }

    let visitsData = [];

    if (dataType === 'OPD') {
      visitsData = session.Visits.all()
        .filter(Visits => Visits.bid === bid)
        .toRefArray();
    }

    if (dataType === 'IPD') {
      visitsData = session.Visits.all()
        .filter(Visits => Visits.an === an)
        .filter(Visits => Visits.class === 'IMP')
        .toRefArray();
    }

    setvisitData({ visitsData });

    if (visitsData.length > 0 && visitsData[0].labResults.length > 0) {
      for (var valLabs of visitsData[0].labResults) {
        session.Labs.upsert(valLabs);
      }
    }

    let Labs = [];

    if (dataType === 'IPD') {
      Labs = session.Labs.all()
        .filter(Labs => Labs.cid === cid)
        .filter(Labs => Labs.an !== '')
        .filter(Labs => Labs.an === visitsData[0].an)
        .orderBy(Labs => Labs.labHeadData.orderDateTime, 'desc')
        .toRefArray();
    } else {
      Labs = session.Labs.all()
        .filter(Labs => Labs.cid === cid)
        .filter(Labs => Labs.an === '')
        .filter(Labs => Labs.vn === visitsData[0].vn)
        .orderBy(Labs => Labs.labHeadData.orderDateTime, 'desc')
        .toRefArray();
    }

    let Pag = [];
    let Tables = [];

    function createData(LabName, LabResult, LabResultNormal) {
      return { LabName, LabResult, LabResultNormal };
    }

    let nn = 1;
    for (var val2 of Labs) {
      if (dataType === 'OPD' && val2.vn !== '') {
        Tables = [];

        for (var val3 of val2.labReportData) {
          if (val3.labItemsNameRef !== '') {
            Tables.push(createData(val3.labItemsNameRef, val3.labOrderResult, val3.labItemsNormalValueRef));
          }
        }

        Pag.push({ labTables: Tables, LabData: val2 });
      }
      if (dataType === 'IPD' && val2.an !== '') {
        Tables = [];

        for (var val4 of val2.labReportData) {
          if (val4.labItemsNameRef !== '') {
            Tables.push(createData(val4.labItemsNameRef, val4.labOrderResult, val4.labItemsNormalValueRef));
          }
        }

        Pag.push({ labTables: Tables, LabData: val2 });
      }
      nn = nn + 1;
    }

    setPage(Pag);
  };

  return (
    <div>
      {visitData && (
        <div>
          {visitData.visitsData.map((items, index) => (
            <div key={index}>
              {pageData.length > 0 && (
                <Card variant='outlined' className='visit-detail-card'>
                  <ExpansionPanel
                    style={{ width: '100%' }}
                    className='MuiAppBar-color-custom '
                    expanded={isExpanded}
                    onChange={e => (isExpanded ? setIsExpanded(false) : setIsExpanded(true))}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon className='app-text-color' />}
                      aria-controls='panel2a-content'
                      id='panel2a-header'
                    >
                      <Typography className='font-size-20px'>Lab ({dataType})</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div
                        style={{
                          maxHeight: '800px',
                          overflowX: 'hidden',
                          overflowY: 'auto',
                          width: '100%',
                        }}
                        className='overflow-scrolling-touch'
                      >
                        {pageData.map((itemsLab, indexLab) => (
                          <div key={indexLab} className='font-size-14px margin-bottom-14px'>
                            <Alert variant='warning'>
                              <Row>
                                <Col lg={12}>
                                  <h6>
                                    Lab Report : {itemsLab.LabData.labHeadData.labOrderNumber} | Confirm Report :{' '}
                                    {itemsLab.LabData.labHeadData.confirmReport}
                                  </h6>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <h6>Lab Form : {itemsLab.LabData.labHeadData.formName}</h6>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6}>
                                  <h6>
                                    Order Date :{' '}
                                    {itemsLab.LabData.labHeadData.orderDateTime &&
                                      ThaiDateTime(itemsLab.LabData.labHeadData.orderDateTime)}
                                  </h6>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg={6}>
                                  <h6>RTF : {itemsLab.LabData.labHeadData.resultRtf}</h6>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg={12}>
                                  <TableContainer component={Paper} style={{ overflowX: 'inherit' }}>
                                    <Table size='small' stickyHeader aria-label='a dense table'>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell align='left' style={{ width: '50px' }}>
                                            Lab Name
                                          </TableCell>
                                          <TableCell align='left'>Result</TableCell>
                                          <TableCell align='left' style={{ width: '200px' }}>
                                            Result Normal
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {itemsLab.labTables.map((row, key1) => (
                                          <TableRow key={key1}>
                                            <TableCell component='th' scope='row' className='font-size-12px'>
                                              {row.LabName}
                                            </TableCell>
                                            <TableCell align='left' className='font-size-12px'>
                                              {row.LabResult}
                                            </TableCell>
                                            <TableCell align='left' className='font-size-12px'>
                                              {row.LabResultNormal}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Col>
                              </Row>
                            </Alert>
                          </div>
                        ))}
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Card>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PtLab));
