import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, useHistory, withRouter } from 'react-router-dom';
import { Online } from 'react-detect-offline';

// components
import Home from '../components/Homes/Home';
import ComingSoon from '../components/Homes/ComingSoon';
import UserLogin from '../components/Authentications/UserLogin';
import AppBackdrop from '../components/AppBackdrop';
import PatientEmrMain from '../components/PatientEmr/PatientEmrMain';
import { ReferIn, ReferOut } from '../components/Refers';
import OnlineGatewayHosp from '../components/Refers/OnlineGatewayHosp';
import EmtDashboard from '../components/EmtDashboard/EmtDashboard';
import GeospatialOutbreak from '../components/GeospatialOutbreak/GeospatialOutbreak';
import Settings from './Settings/Settings';
import EditCategory from './Settings/EditCategory/EditCategory';
import BillingsCharge from './BillingsCharge/BillingsCharge';
import ChargeSummary from './BillingsCharge/ChargeSummary/ChargeSummary';
import ChargePatients from './BillingsCharge/ChargePatients/ChargePatients';
import PatientBilling from './PatientBilling/PatientBilling';
import { UserForm, UserManagement } from './UserManagement';
import { HospitalForm, HospitalManagement } from './HospitalManagement';
import { ReferReportMain, ReferReportChwMain } from '../components/Report';

function ContainerMain({ appData }) {
  const history = useHistory();

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    redirect({ previousUser: appData?.previousUser, currentUser: appData?.loginData?.username });

    window.addEventListener('resize', updateWidthAndHeight);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  }, [appData.loginData, appData.previousUser]);

  //redirect to home page if switch account
  const redirect = ({ previousUser, currentUser }) => {
    if (currentUser && previousUser) {
      if (currentUser !== previousUser) {
        setTimeout(() => {
          history.push('/');
        }, 500);
      }
    }
  };

  return (
    <div className='container-main'>
      <div>
        <AppBackdrop />
        <UserLogin />
      </div>
      <div className='ContainerMainCustom'>
        {appData.idToken && (
          <Switch>
            <Route exact path='/' render={props => <Home {...props} Layout={Home} />} />
            <Route
              exact
              path='/patient-emr/:cid?/:bid?'
              render={props => <PatientEmrMain {...props} width={width} height={height} Layout={PatientEmrMain} />}
            />
            <Route
              exact
              path='/refer-in/:startDate?/:endDate?'
              render={props => <ReferIn {...props} width={width} height={height} Layout={ReferIn} />}
            />
            <Route
              exact
              path='/refer-out/:startDate?/:endDate?'
              render={props => <ReferOut {...props} width={width} height={height} Layout={ReferOut} />}
            />

            {/* Coming Soon */}

            <Route
              exact
              path='/register'
              render={props => <ComingSoon {...props} width={width} height={height} Layout={ComingSoon} />}
            />

            <Route
              exact
              path='/screening'
              render={props => <ComingSoon {...props} width={width} height={height} Layout={ComingSoon} />}
            />

            <Route
              exact
              path='/examination'
              render={props => <ComingSoon {...props} width={width} height={height} Layout={ComingSoon} />}
            />

            <Route
              exact
              path='/round-ward'
              render={props => <ComingSoon {...props} width={width} height={height} Layout={ComingSoon} />}
            />

            <Route
              exact
              path='/hosp-refer-report'
              render={props => <ReferReportMain {...props} width={width} height={height} Layout={ReferReportMain} />}
            />
            <Route
              exact
              path='/chw-refer-report'
              render={props => (
                <ReferReportChwMain {...props} width={width} height={height} Layout={ReferReportChwMain} />
              )}
            />
            <Route
              exact
              path='/emt-dashboard'
              render={props => <EmtDashboard {...props} width={width} height={height} Layout={EmtDashboard} />}
            />
            <Route
              exact
              path='/geospatial-outbreak'
              render={props => (
                <GeospatialOutbreak {...props} width={width} height={height} Layout={GeospatialOutbreak} />
              )}
            />

            <Route
              exact
              path='/online-hospital'
              render={props => (
                <OnlineGatewayHosp {...props} width={width} height={height} Layout={OnlineGatewayHosp} />
              )}
            />

            <Route
              exact
              path='/billing-charge/to'
              render={props => <BillingsCharge {...props} width={width} height={height} Layout={BillingsCharge} />}
            />

            <Route
              exact
              path='/billing-charge/from'
              render={props => <BillingsCharge {...props} width={width} height={height} Layout={BillingsCharge} />}
            />

            <Route
              exact
              path='/billing-charge/to/:selectedHosp/:monthIndex'
              render={props => <ChargeSummary {...props} width={width} height={height} Layout={ChargeSummary} />}
            />

            <Route
              exact
              path='/billing-charge/from/:selectedHosp/:monthIndex'
              render={props => <ChargeSummary {...props} width={width} height={height} Layout={ChargeSummary} />}
            />

            <Route
              exact
              path='/billing-charge/to/:selectedHosp/:monthIndex/:category/patients'
              render={props => <ChargePatients {...props} width={width} height={height} Layout={ChargePatients} />}
            />

            <Route
              exact
              path='/billing-charge/from/:selectedHosp/:monthIndex/:category/patients'
              render={props => <ChargePatients {...props} width={width} height={height} Layout={ChargePatients} />}
            />

            <Route
              exact
              path='/billing-charge/to/:selectedHosp/:monthIndex/:category/patients/:cid/'
              render={props => <PatientBilling {...props} width={width} height={height} Layout={PatientBilling} />}
            />

            <Route
              exact
              path='/billing-charge/from/:selectedHosp/:monthIndex/:category/patients/:cid/'
              render={props => <PatientBilling {...props} width={width} height={height} Layout={PatientBilling} />}
            />

            <Route
              exact
              path='/settings'
              render={props => <Settings {...props} width={width} height={height} Layout={Settings} />}
            />

            <Route
              exact
              path='/settings/edit-category/'
              render={props => <EditCategory {...props} width={width} height={height} Layout={EditCategory} />}
            />

            <Route
              exact
              path='/settings/edit-category/:id'
              render={props => <EditCategory {...props} width={width} height={height} Layout={EditCategory} />}
            />

            <Route
              exact
              path='/user-management'
              render={props => <UserManagement {...props} width={width} height={height} Layout={UserManagement} />}
            />

            <Route
              exact
              path='/user-management/add-edit-user'
              render={props => <UserForm {...props} width={width} height={height} Layout={UserForm} />}
            />

            <Route
              exact
              path='/user-management/add-edit-user/:id'
              render={props => <UserForm {...props} width={width} height={height} Layout={UserForm} />}
            />

            <Route
              exact
              path='/hospital-management'
              render={props => (
                <HospitalManagement {...props} width={width} height={height} Layout={HospitalManagement} />
              )}
            />

            <Route
              exact
              path='/hospital-management/add-edit-hospital'
              render={props => <HospitalForm {...props} width={width} height={height} Layout={HospitalForm} />}
            />

            <Route
              exact
              path='/hospital-management/add-edit-hospital/:hospCode'
              render={props => <HospitalForm {...props} width={width} height={height} Layout={HospitalForm} />}
            />
          </Switch>
        )}
      </div>
      <div>
        <Online>
          {appData.loginData && (
            <div
              style={{
                top: height - 25 + 'px',
                position: 'fixed',
                left: width - 210 + 'px',
              }}
              className='user-online'
            >
              <div>{appData.loginData.hospName}</div>
            </div>
          )}
        </Online>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

export default withRouter(connect(mapStateToProps)(ContainerMain));
