import axios from 'axios';
import config from 'react-global-configuration';

export default dataRequest => {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      const { idToken, hospCode, year, selectedHospmain, month, asProvider } = dataRequest;
      const hospmains = await axios.get(`${config.get('ROOT_API')}/v1/billing/getcachehospmain?hospCode=${hospCode}`, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      const provider = asProvider ? hospCode : selectedHospmain;
      const payer = asProvider ? selectedHospmain : hospCode;
      const currentYear = year || new Date().getFullYear();
      const billings = await axios.get(
        `${config.get('ROOT_API')}/v1/billingsummary?provider=${provider}&payer=${payer}&year=${currentYear}`,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      const billingSummary = billings?.data?.result?.data?.find(bill => bill.monthIndex === month);
      const billingData = {
        selectedHospmain: hospmains?.data?.result?.find(hosp => hosp.hospCode === selectedHospmain),
        billingSummary,
        FetchingStatus: false,
      };
      return resolve(billingData);
    }, 200);
  });
};
