import { APPDATA } from '../../../constants/appData';
import { DeleteAttachment } from '../../../api/Refers/ReferOut';

const deleteAttachment = ({ idToken, id }) => {
  return {
    type: APPDATA,
    payload: DeleteAttachment({ idToken, id }),
  };
};

export default deleteAttachment;
