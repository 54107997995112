import axios from 'axios';
import config from 'react-global-configuration';

const UploadFile = async ({ idToken, file }) => {
  const url = `${config.get('ROOT_API')}/v1/refer/upload`;

  const formData = new FormData();
  formData.append('files', file);

  return await axios
    .post(url, formData, {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    .then(res => {
      return res.data[0];
    })
    .catch(err => {
      console.log('upload file axios error');
      throw err;
    });
};

export default UploadFile;
