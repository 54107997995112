import React, { Component } from 'react';
import { connect } from 'react-redux';

// actions
import { fetchLogout } from '../../actions/logout';

// functions
import { DateTimeToStrDatetime } from '../../functions/FuncDateTimes';
import { StrEncrypt, StrDecrypt } from '../../functions/FuncPerjer';

class UserOnlineTime extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.logoutApp = this.logoutApp.bind(this);
    this.TimerCheck = this.TimerCheck.bind(this);
    this.DateTimeDiff = this.DateTimeDiff.bind(this);
    this.SetTimeNow = this.SetTimeNow.bind(this);
  }

  logoutApp(previousUser) {
    const appData = {
      loginData: null,
      loginStatus: false,
      idToken: '',
      FetchingStatus: false,
      previousUser,
    };

    sessionStorage.setItem('Token', '');
    localStorage.setItem('Token', '');

    this.props.fetchLogout(appData);
  }

  DateTimeDiff(ds) {
    let eventStartTime = new Date(ds.replace(' ', 'T'));
    let eventEndTime = new Date();
    let duration = eventEndTime.valueOf() - eventStartTime.valueOf();
    duration = duration / 1000 / 60;
    return duration;
  }

  TimerCheck() {
    if (
      this.DateTimeDiff(StrDecrypt(localStorage.getItem('LastActiveTime'), 'TdsGdfcvd')) > 60 * 2 &&
      localStorage.getItem('Token') !== ''
    ) {
      this.logoutApp(this.props.appData?.loginData?.username);
    }

    if (
      this.DateTimeDiff(StrDecrypt(localStorage.getItem('LoginTime'), 'TpkoHksVbd')) > 60 * 8 &&
      localStorage.getItem('Token') !== ''
    ) {
      this.logoutApp(this.props.appData?.loginData?.username);
    }

    if (localStorage.getItem('LastActiveTime') <= '') {
      localStorage.setItem('LastActiveTime', StrEncrypt(DateTimeToStrDatetime(new Date()), 'TdsGdfcvd'));
    }

    setTimeout(() => {
      this.TimerCheck();
    }, 10000);
  }

  SetTimeNow() {
    localStorage.setItem('LastActiveTime', StrEncrypt(DateTimeToStrDatetime(new Date()), 'TdsGdfcvd'));
  }

  componentDidMount() {
    this.TimerCheck();
  }

  componentDidUpdate() {
    this.SetTimeNow();
  }

  render() {
    return <div />;
  }
}

function mapStateToProps(state) {
  return {
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLogout: appData => dispatch(fetchLogout(appData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserOnlineTime);
