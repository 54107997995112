import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import NavigationMain from '../components/NavigationMain/NavigationMain';
import ContainerMain from '../components/ContainerMain';

const Index = () => (
  <div>
    <Route render={props => <NavigationMain {...props} />} />
    <Route render={props => <ContainerMain {...props} />} />
  </div>
);

export default withRouter(Index);
