import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

// functions
import { ThaiDateTime } from '../../functions/FuncDateTimes';

// mui
import Card from '@material-ui/core/Card';

const session = orm.session();

function PtRxOrder({ bid, an, cid, dataType, appData }) {
  const [visitData, setvisitData] = useState(null);
  const [bidData, setDataBid] = useState(false);
  const [pageDrugData, setPageDrug] = useState(null);
  const [pageNoneDrugData, setPageNoneDrug] = useState(null);

  useEffect(() => {
    if (bid !== bidData) {
      setDataBidFc(bid);
      setvisitDataFc(bid);
    }
  });

  const setDataBidFc = bid => {
    setDataBid(bid);
  };

  const setvisitDataFc = bid => {
    const visits = appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.bid === bid;
      },
    });

    session.MedicalSupplieItems.delete({
      where(record) {
        return record.cid === cid;
      },
    });

    for (var val of visits) {
      session.Visits.upsert(val);
    }

    let visitsData = [];

    if (dataType === 'OPD') {
      visitsData = session.Visits.all()
        .filter(Visits => Visits.bid === bid)
        // .filter((Visits) => Visits.class === 'AMB')
        .toRefArray();
    }

    if (dataType === 'IPD') {
      visitsData = session.Visits.all()
        .filter(Visits => Visits.an === an)
        .filter(Visits => Visits.class === 'IMP')
        .toRefArray();
    }

    setvisitData({ visitsData });

    if (visitsData.length > 0 && visitsData[0].billingItems.length > 0) {
      for (var MedicalSupplieItems of visitsData[0].billingItems) {
        session.MedicalSupplieItems.upsert(MedicalSupplieItems);
      }
    }

    let DrugSupplieItems = [];
    let NoneDrugSupplieItems = [];

    if (dataType === 'IPD') {
      DrugSupplieItems = session.MedicalSupplieItems.all()
        .filter(MedicalSupplieItems => MedicalSupplieItems.cid === cid)
        .filter(MedicalSupplieItems => MedicalSupplieItems.an !== '')
        .filter(MedicalSupplieItems => MedicalSupplieItems.an === visitsData[0].an)
        .filter(MedicalSupplieItems => MedicalSupplieItems.tmtCode !== '')
        .orderBy(MedicalSupplieItems => MedicalSupplieItems.rxDateTime, 'desc')
        .toRefArray();
      NoneDrugSupplieItems = session.MedicalSupplieItems.all()
        .filter(MedicalSupplieItems => MedicalSupplieItems.cid === cid)
        .filter(MedicalSupplieItems => MedicalSupplieItems.an !== '')
        .filter(MedicalSupplieItems => MedicalSupplieItems.an === visitsData[0].an)
        .filter(MedicalSupplieItems => MedicalSupplieItems.tmtCode === '')
        .orderBy(MedicalSupplieItems => MedicalSupplieItems.rxDateTime, 'desc')
        .toRefArray();
    } else {
      DrugSupplieItems = session.MedicalSupplieItems.all()
        .filter(MedicalSupplieItems => MedicalSupplieItems.cid === cid)
        .filter(MedicalSupplieItems => MedicalSupplieItems.an === '')
        .filter(MedicalSupplieItems => MedicalSupplieItems.vn === visitsData[0].vn)
        .filter(MedicalSupplieItems => MedicalSupplieItems.tmtCode !== '')
        .orderBy(MedicalSupplieItems => MedicalSupplieItems.rxDateTime, 'desc')
        .toRefArray();
      NoneDrugSupplieItems = session.MedicalSupplieItems.all()
        .filter(MedicalSupplieItems => MedicalSupplieItems.cid === cid)
        .filter(MedicalSupplieItems => MedicalSupplieItems.an === '')
        .filter(MedicalSupplieItems => MedicalSupplieItems.vn === visitsData[0].vn)
        .filter(MedicalSupplieItems => MedicalSupplieItems.tmtCode === '')
        .orderBy(MedicalSupplieItems => MedicalSupplieItems.rxDateTime, 'desc')
        .toRefArray();
    }

    setPageDrug(DrugSupplieItems);
    setPageNoneDrug(NoneDrugSupplieItems);
  };

  return (
    <div>
      {visitData && (
        <div>
          {visitData.visitsData.map((items, index) => (
            <div key={index}>
              {items.billingItems && pageDrugData.length > 0 && (
                <Card variant='outlined' className='visit-detail-card margin-bottom-8px'>
                  <Row>
                    <Col lg={12}>
                      <h5>Drug Items ({dataType})</h5>
                    </Col>
                  </Row>
                  <div className='overflow-scrolling-touch drugItems'>
                    <Row>
                      {pageDrugData.map((itemsDrug, indexDrug) => (
                        <Col lg={6} key={indexDrug}>
                          <div className='font-size-14px'>
                            <Alert variant='secondary'>
                              <Row>
                                <Col lg={12}>
                                  <div className='float-left'>
                                    {indexDrug + 1}. {itemsDrug.drugNondugFullName}
                                  </div>
                                  <div className='float-right'>฿{itemsDrug.sumPrice}</div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <div className='font-size-12px'>{itemsDrug.drugUsageEname}</div>
                                  <div className='font-size-10px'>
                                    Log. {itemsDrug.rxDateTime && ThaiDateTime(itemsDrug.rxDateTime)}
                                  </div>
                                </Col>
                              </Row>
                            </Alert>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Card>
              )}

              {items.billingItems && pageNoneDrugData.length > 0 && (
                <Card variant='outlined' className='visit-detail-card margin-bottom-8px hide-on-print'>
                  <Row>
                    <Col lg={12}>
                      <h5>Other Items ({dataType})</h5>
                    </Col>
                  </Row>
                  <div className='overflow-scrolling-touch drugItems'>
                    <Row>
                      {pageNoneDrugData.map((itemsDrug, indexDrug) => (
                        <Col lg={6} key={indexDrug}>
                          <div className='font-size-14px'>
                            <Alert variant='secondary'>
                              <Row>
                                <Col lg={12}>
                                  <div className='float-left'>
                                    {indexDrug + 1}. {itemsDrug.drugNondugFullName}
                                  </div>
                                  <div className='float-right'>฿{itemsDrug.sumPrice}</div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <div className='font-size-12px'>{itemsDrug.drugUsageEname}</div>
                                  <div className='font-size-10px'>
                                    Log. {itemsDrug.rxDateTime && ThaiDateTime(itemsDrug.rxDateTime)}
                                  </div>
                                </Col>
                              </Row>
                            </Alert>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Card>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PtRxOrder));
