import axios from 'axios';
import config from 'react-global-configuration';

interface params {
  idToken: string;
}

export const FetchHospitalTypeList = ({ idToken }: params) => {
  let dispatch = {};
  const url = `${config.get('ROOT_API')}/v1/lists/hospitaltype`;

  return axios
    .get(url, {
      headers: { authorization: `Bearer ${idToken}` },
    })
    .then(res => {      
      dispatch = {
        hospitalTypeList: res.data,
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(err => {
      console.log('fetch hospital type list axios error');

      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
