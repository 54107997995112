import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

//style
import { HospitalFormContainer, Form, useStyles } from './HospitalForm.style';

//actions
import {
  addHospital,
  editHospital,
  fetchHospitalList,
  fetchHospitalTypeList,
} from '../../../actions/HospitalManagement';

// components
import { ListboxComponent } from '../../Utility/ListboxComponent';

//mui
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Chip, MenuItem, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Autocomplete } from '@material-ui/lab';

const HospitalForm = props => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, handleSubmit, errors } = useForm();

  const [loading, setLoading] = useState(false);
  const [selectedHospital, setSelectedHospital] = useState({});
  const [selectedHospitalType, setSelectedHospitalType] = useState('');
  const [members, setMembers] = useState([]);

  const { location } = props;
  const hospital = location?.state?.hospital;

  const { appData } = useSelector(state => state);
  const { idToken, hospitalList, hospitalTypeList, alert, permission } = appData;

  const ActionBtn = () => {
    return (
      <div className='action'>
        <Button
          disabled={loading}
          className='cancel'
          variant='contained'
          onClick={() => history.push('/hospital-management')}
        >
          ยกเลิก
        </Button>
        <Button disabled={loading} type='submit' color='primary' variant='contained'>
          ยืนยัน
        </Button>
      </div>
    );
  };

  useEffect(() => {
    handleFetchHospitalList({ idToken, limit: '1000' });
    handleFetchHospitalTypeList({ idToken });
  }, []);

  useEffect(() => {
    if (alert.type === 'success') {
      !permission?.hospitalManagement && swal('อัพเดทข้อมูลเรียบร้อย', 'กรุณาล็อกอินใหม่อีกครั้ง', 'success');
      history.push('/hospital-management');
    }
  }, [idToken, alert]);

  useEffect(() => {
    let tmpMembers = [];
    hospital?.members?.map(item => tmpMembers.push({ key: uuid(), hospCode: item }));

    setMembers(tmpMembers);

    // set default hospital type in case update hospital
    hospital?.type && setSelectedHospitalType(hospital?.type);
  }, [hospital]);

  // set default hospital type in case add new hospital
  useEffect(() => {
    !hospital?.type && setSelectedHospitalType(hospitalTypeList[0]);
  }, [hospitalTypeList]);

  const handleFetchHospitalList = ({ idToken, page, limit, sortColumn, sortType, filterText, filterList }) => {
    dispatch(fetchHospitalList({ idToken, page, limit, sortColumn, sortType, filterText, filterList }));
  };

  const handleFetchHospitalTypeList = ({ idToken }) => {
    dispatch(fetchHospitalTypeList({ idToken }));
  };

  const onSubmit = _hospital => {
    setLoading(true);

    // update hospital
    if (hospital) {
      const body = {
        hospCode: hospital?.hospCode,
        hospName: hospital?.hospName !== _hospital?.hospName ? _hospital?.hospName : '',
        members: members?.map(item => item?.hospCode),
        type: hospital?.type !== selectedHospitalType ? selectedHospitalType : '',
        level: hospital?.level !== _hospital?.level ? _hospital?.level : '',
      };

      dispatch(editHospital({ idToken, body }));
    }
    // add new hospital
    else {
      const body = {
        hospCode: _hospital?.hospCode,
        hospName: _hospital?.hospName,
        members: members?.map(item => item?.hospCode),
        type: selectedHospitalType,
        level: _hospital?.level,
      };

      dispatch(addHospital({ idToken, body }));
    }

    setLoading(false);
  };

  const handleDeleteHospCodeList = chipToDelete => {
    setMembers(chips => chips.filter(chip => chip.key !== chipToDelete.key));
  };

  return (
    <HospitalFormContainer>
      <Form elevation={2}>
        <h4 className='title'>{hospital ? 'แก้ไข รพ.' : ' รพ.ใหม่'}</h4>

        <form className='form' onSubmit={handleSubmit(onSubmit)}>
          <Controller
            as={
              <TextField
                required
                type='number'
                className='input'
                label='รหัส รพ.'
                variant='outlined'
                disabled={loading || hospital}
              />
            }
            rules={{ minLength: 5, maxLength: 5 }}
            control={control}
            name='hospCode'
            defaultValue={hospital?.hospCode || ''}
          />
          {(errors?.hospCode?.type === 'maxLength' || errors?.hospCode?.type === 'minLength') && (
            <Typography color='error'>รหัส รพ. ต้องเป็นตัวเลข 5 ตัวเท่านั้น</Typography>
          )}

          <Controller
            as={
              <TextField
                required
                className='input'
                label='ชื่อ รพ.'
                variant='outlined'
                disabled={loading || hospital}
              />
            }
            control={control}
            name='hospName'
            defaultValue={hospital?.hospName || ''}
          />

          {selectedHospitalType && (
            <Autocomplete
              onChange={(_option, value) => value && setSelectedHospitalType(value)}
              ListboxComponent={ListboxComponent}
              options={hospitalTypeList}
              renderInput={params => (
                <TextField {...params} fullWidth variant='outlined' margin='normal' label='ประเภท รพ.' />
              )}
              defaultValue={selectedHospitalType}
              disabled={loading || hospital}
            />
          )}

          <Controller
            as={<TextField select className='input' label='ระดับ รพ.' variant='outlined' disabled={loading} />}
            control={control}
            name='level'
            defaultValue={hospital?.level || 1}
          >
            {[
              { label: 'ระดับ 1 - รพสต สสอ ศูนย์อนามัยอื่น ๆ', value: 1 },
              { label: 'ระดับ 2 - รพ.ชุมชน', value: 2 },
              { label: 'ระดับ 3 - รพ.ทั่วไป', value: 3 },
              { label: 'ระดับ 4 - รพ.ศูนย์', value: 4 },
              { label: 'ระดับ 5 - รพ.แพทย์ หรือ เฉพาะทางอื่น ๆ', value: 5 },
            ].map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Controller>

          <div className={classes.chipWrapper}>
            {members?.map(item => (
              <Chip
                label={item.hospCode}
                size='small'
                color='secondary'
                variant='outlined'
                onDelete={e => handleDeleteHospCodeList(item)}
                className={classes.chip}
              />
            ))}
          </div>

          <Autocomplete
            onChange={(_option, value) => value && setMembers([...members, { key: uuid(), hospCode: value.hospCode }])}
            ListboxComponent={ListboxComponent}
            // TODO: maybe must take out provincial heath office account after know list of provincial office hospCode
            options={hospitalList?.docs?.filter(item => item?.hospCode !== selectedHospital?.hospCode)}
            getOptionLabel={option => `${option?.hospCode} : ${option?.hospName}`}
            renderInput={params => (
              <TextField {...params} fullWidth variant='outlined' margin='normal' label='รายการ รพ. ลูกข่าย' />
            )}
          />

          <div className='alert-container'>{alert.show && <Alert severity={alert.type}>{alert.msg}</Alert>}</div>

          <ActionBtn />
        </form>

        {loading && <LinearProgress />}
      </Form>
    </HospitalFormContainer>
  );
};

export default HospitalForm;
