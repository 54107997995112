import axios from 'axios';
import config from 'react-global-configuration';

export default dataRequest => {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      const { idToken, hospCode, categoryObjectId } = dataRequest;
      await axios
        .post(
          `${config.get('ROOT_API')}/v1/billingstatus/payerFinishReviewingBill`,
          { hospCode, categoryObjectId },
          { headers: { Authorization: `Bearer ${idToken}` } }
        )
        .then(res => {
          const billingData = {
            FetchingStatus: false,
            chargeModal: false,
          };

          return resolve(billingData);
        })
        .catch(err => {
          const billingData = {
            FetchingStatus: false,
            alert: {
              show: true,
              msg: 'ทำการตอบรับการเรียกเก็บไม่สำเร็จ',
              type: 'error',
            },
          };

          return resolve(billingData);
        });
    }, 200);
  });
};
