import { APPDATA } from '../../../constants/appData';
import { AttachFiles } from '../../../api/Refers/ReferOut';

const attachFiles = ({ idToken, attachments, bid, existsAttachments }) => {
  return {
    type: APPDATA,
    payload: AttachFiles({ idToken, attachments, bid, existsAttachments }),
  };
};

export default attachFiles;
