import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ zIndex }) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: zIndex.drawer + 1,
  },
  MuiButtonCustom: {
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  large: {
    backgroundColor: '#E83D84',
    padding: '8px 4px 2px 4px',
    marginTop: '2px',
    visibility: 'hidden',
  },
  large2: {
    backgroundColor: '#222222',
    padding: '8px 4px 2px 4px',
    marginTop: '8px',
    left: '20px',
  },
  large3: {
    backgroundColor: '#E83D84',
    padding: '8px 4px 2px 4px',
    marginTop: '2px',
    marginRight: '10px',
  },
  large4: {
    backgroundColor: '#E83D84',
    padding: '8px 4px 2px 4px',
    marginTop: '8px',
    left: '20px',
  },
  large5: {
    backgroundColor: '#E83D84',
    marginTop: '8px',
    left: '20px',
  },
  large6: {
    backgroundColor: '#E83D84',
    marginTop: '2px',
    marginRight: '10px',
  },
  overflow_x_auto: {
    overflow: 'auto',
  },
  RightMenu: {
    width: '300px',
    marginLeft: 'auto',
    textAlign: 'right',
  },
  RightMenuIcon: {
    float: 'right',
    padding: '4px',
  },
  RightMenuIconAvatar: {
    float: 'right',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
}));
