import { ORM } from 'redux-orm';
import Book from './Book';
import Author from './Author';
import Patients from './Patients';
import Visits from './Visits';
import VisitsHosp from './VisitsHosp';
import Labs from './Labs';
import MedicalSupplieItems from './MedicalSupplieItems';
import ReferHosp from './ReferHosp';
import ReferDatas from './ReferDatas';
import ReferInDatas from './ReferInDatas';
import ReferOutDatas from './ReferOutDatas';
import ReferBackDatas from './ReferBackDatas';
import TempData from './TempData';
import Temp2Data from './Temp2Data';
import Temp3Data from './Temp3Data';

const orm = new ORM();
orm.register(
  Book,
  Author,
  Patients,
  Visits,
  VisitsHosp,
  Labs,
  MedicalSupplieItems,
  ReferHosp,
  ReferDatas,
  ReferInDatas,
  ReferOutDatas,
  ReferBackDatas,
  TempData,
  Temp2Data,
  Temp3Data
);

export default orm;
