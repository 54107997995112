import React, { Component } from 'react';
import { connect } from 'react-redux';
import DayjsUtils from '@date-io/dayjs';
import 'dayjs/locale/th';
import orm from '../../models/index';
import dayjs from 'dayjs';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchRefers } from '../../actions/ReferDatas';

// functions
import { DateTimeToStrDate, StrToDateTime, ThaiDateTime, delDays } from '../../functions/FuncDateTimes';
import { StrEncrypt, shortHospName } from '../../functions/FuncPerjer';
import { getReferColor } from '../../functions/Refers';

// constants
import { REJECTED } from '../../constants/Refers/referralStatus';
import { REFER_BACK } from '../../constants/Refers/referType';

// components
import { ReferAttachDialog } from '../Utility/Refers';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// mui
import InputBase from '@material-ui/core/InputBase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import RefreshIcon from '@material-ui/icons/Refresh';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-thai-datepickers';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Button, Menu } from '@material-ui/core';

const session = orm.session();

class ReferOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cid: '',
      hospCodeData: [],
      ReferDatas: [],
      hospCode: 'all',
      selectedDate: null,
      datePickerChang: false,
      referPoint: 'all',
      MenuClose: false,
      startDate: new Date(),
      endDate: new Date(),
      Scid: '',
      Sname: '',
      anchorEl: [],
      DialogAttachOpen: false,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.hospCodeChange = this.hospCodeChange.bind(this);
    this.getReferDatas = this.getReferDatas.bind(this);
    this.setHospCodeData = this.setHospCodeData.bind(this);
    this.setReferDatas = this.setReferDatas.bind(this);
    this.setReferPointFc = this.setReferPointFc.bind(this);
    this.emrViews = this.emrViews.bind(this);
    this.setAppData = this.setAppData.bind(this);
    this.getReferCidDatas = this.getReferCidDatas.bind(this);
    this.onCidChange = this.onCidChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.DialogAttachOpenFc = this.DialogAttachOpenFc.bind(this);
    this.DialogAttachCloseFc = this.DialogAttachCloseFc.bind(this);
  }
  componentDidMount() {
    const { params } = this.props.match;

    if (!params.startDate && this.props.appData.loginData) {
      this.setState({ selectedDate: new Date() });
      this.setState({ startDate: new Date() });
      this.setState({ endDate: new Date() });
      this.props.history.push('/refer-out/' + DateTimeToStrDate(new Date()) + '/' + DateTimeToStrDate(new Date()));
      this.getReferDatas(DateTimeToStrDate(new Date()), DateTimeToStrDate(new Date()));
    }

    if (
      (params.startDate !== this.state.startDate || params.endDate !== this.state.endDate) &&
      params.startDate &&
      params.endDate &&
      this.props.appData.loginData
    ) {
      this.setState({ selectedDate: StrToDateTime(params.startDate) });
      this.setState({ startDate: StrToDateTime(params.startDate) });
      this.setState({ endDate: StrToDateTime(params.startDate) });
      this.props.history.push('/refer-out/' + params.startDate + '/' + params.endDate);
      this.getReferDatas(params.startDate, params.endDate);
    }
  }

  componentDidUpdate() {
    const { params } = this.props.match;

    if (
      this.state.selectedDate !== this.state.startDate &&
      this.state.selectedDate &&
      this.state.datePickerChang &&
      this.props.appData.loginData
    ) {
      this.setState({ startDate: this.state.selectedDate });
      this.setState({ datePickerChang: false });

      this.props.history.push(
        '/refer-out/' + DateTimeToStrDate(this.state.selectedDate) + '/' + DateTimeToStrDate(this.state.selectedDate)
      );
      this.getReferDatas(DateTimeToStrDate(this.state.selectedDate), DateTimeToStrDate(this.state.selectedDate));
    }

    if (!this.state.datePickerChang && this.props.appData.loginData) {
      if (params.startDate && params.startDate !== DateTimeToStrDate(this.state.selectedDate)) {
        this.setState({ selectedDate: StrToDateTime(params.startDate) });
        this.props.history.push('/refer-out/' + params.startDate + '/' + params.startDate);
        this.getReferDatas(params.startDate, params.startDate);
      }
    }
  }

  onCidChange(e) {
    const re = /^[0-9\b]+$/;

    if (re.test(e.target.value)) {
      this.setState({ Scid: e.target.value });
      this.setState({ Sname: '' });
    } else {
      this.setState({ Scid: '' });
      this.setState({ Sname: e.target.value });
    }

    this.setState({ cid: e.target.value });
  }

  handleDateChange(date) {
    this.setState({ datePickerChang: true });
    this.setState({ selectedDate: date });
  }

  hospCodeChange(e) {
    // this.setState({ hospCode: e.target.value })
    this.setState({ referPoint: 'all' });
    this.setState({ hospCode: e.target.value });
    let referData = [];

    if (e.target.value !== 'all') {
      referData = session.ReferDatas.all()
        .filter(ReferDatas => ReferDatas.data.toHospCode === e.target.value)
        .orderBy(ReferDatas => ReferDatas.data.referDateTime, 'desc')
        .toRefArray();
    } else {
      referData = session.ReferDatas.all()
        .filter(ReferDatas => ReferDatas.data._id !== '')
        .orderBy(ReferDatas => ReferDatas.data.referDateTime, 'desc')
        .toRefArray();
    }

    this.setState({ ReferDatas: referData });
  }

  setReferPointFc(e) {
    this.setState({ hospCode: 'all' });
    this.setState({ referPoint: e.target.value });

    let referData = [];

    if (e.target.value !== 'all') {
      if (e.target.value === 'er') {
        referData = session.ReferDatas.all()
          .filter(ReferDatas => ReferDatas.data.referPoint === 'ER')
          .orderBy(ReferDatas => ReferDatas.data.referDateTime, 'desc')
          .toRefArray();
      } else if (e.target.value === 'ipd') {
        referData = session.ReferDatas.all()
          .filter(ReferDatas => ReferDatas.data.referPoint === 'IPD')
          .orderBy(ReferDatas => ReferDatas.data.referDateTime, 'desc')
          .toRefArray();
      } else {
        referData = session.ReferDatas.all()
          .filter(ReferDatas => ReferDatas.data.referPoint !== 'ER' && ReferDatas.data.referPoint !== 'IPD')
          .orderBy(ReferDatas => ReferDatas.data.referDateTime, 'desc')
          .toRefArray();
      }
    } else {
      referData = session.ReferDatas.all()
        .filter(ReferDatas => ReferDatas.data._id !== '')
        .orderBy(ReferDatas => ReferDatas.data.referDateTime, 'desc')
        .toRefArray();
    }

    this.setState({ ReferDatas: referData });
  }

  setCreateStatusFc() {
    this.setState({ createStatus: true });
  }

  setHospCodeData() {
    const referDb = this.props.appData.emrData.referDatas;

    session.ReferHosp.delete({
      where(record) {
        return record.id !== '';
      },
    });

    for (var val of referDb) {
      session.ReferHosp.upsert({
        id: val.data.toHospCode,
        hospName: val.toHospName ? shortHospName(val.toHospName) : val.data.toHospCode,
      });
    }

    let hospCodeData2 = session.ReferHosp.all()
      .filter(ReferHosp => ReferHosp.id !== '')
      .orderBy(ReferHosp => ReferHosp.hospName, 'asc')
      .toRefArray();

    this.setState({ hospCodeData: hospCodeData2 });
  }

  setReferDatas() {
    const referDb = this.props.appData.emrData.referDatas;

    session.ReferDatas.delete({
      where(record) {
        return record._id !== '';
      },
    });

    for (var val of referDb) {
      session.ReferDatas.upsert(val);
    }

    let referData = session.ReferDatas.all()
      .filter(ReferDatas => ReferDatas.data._id !== '')
      .orderBy(ReferDatas => ReferDatas.data.referDateTime, 'desc')
      .toRefArray();

    this.setState({ ReferDatas: referData });
  }

  getReferDatas(startDate, endDate, incomplete = false) {
    let dataRequest = {
      idToken: this.props.appData.idToken,
      startDate: startDate,
      endDate: endDate,
      fromHospCode: this.props.appData.loginData.hospCode,
      toHospCode: '',
      limit: this.props.appProperty.visitLimit,
      incomplete,
    };

    let res = this.props.fetchRefers(dataRequest);

    let obj = this;
    res.then(
      function (v) {
        obj.setState({ hospCode: 'all' });
        obj.setState({ referPoint: 'all' });

        obj.setHospCodeData();
        obj.setReferDatas();

        const appPropertyData = {
          visitSelect: '',
          FetchingStatus: false,
        };

        obj.setAppData(appPropertyData);
      },
      function (e) {
        // TypeError: Throwing
        if (e.status !== 200) {
          alert('การค้นห้าข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
        } else {
        }
      }
    );
  }

  getReferCidDatas(e) {
    e.preventDefault();

    if (this.state.cid !== '') {
      let cid = '';
      let name = '';

      if (this.state.Scid.trim().length === 13) {
        cid = this.state.Scid;
      }

      if (this.state.Sname.trim().length > 0 && this.state.Sname !== '') {
        name = this.state.Sname;
      }

      if (cid <= '' && name <= '') {
        alert('กรุณากรอก CID / ชื่อ สกุล ไม่ถูกต้อง !');
      } else {
        let std = DateTimeToStrDate(delDays(new Date(), 366));
        let ste = DateTimeToStrDate(new Date());

        let dataRequest = {
          idToken: this.props.appData.idToken,
          startDate: std,
          endDate: ste,
          fromHospCode: this.props.appData.loginData.hospCode,
          toHospCode: '',
          cid: cid,
          name: name,
          limit: this.props.appProperty.visitLimit,
        };

        let res = this.props.fetchRefers(dataRequest);

        let obj = this;
        res.then(
          function (v) {
            obj.setState({ hospCode: 'all' });
            obj.setState({ referPoint: 'all' });

            obj.setHospCodeData();
            obj.setReferDatas();

            const appPropertyData = {
              visitSelect: '',
              FetchingStatus: false,
            };

            obj.setAppData(appPropertyData);
          },
          function (e) {
            // TypeError: Throwing
            if (e.status !== 200) {
              alert('การค้นห้าข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
            } else {
            }
          }
        );
      }
    }
  }

  setAppData(appPropertyData) {
    this.props.appPropertySet(appPropertyData);
  }

  emrViews(cid, fromHospCode, vn) {
    let cidEncrypted2 = StrEncrypt(cid, this.props.appProperty.ScKey);
    this.props.history.push('/patient-emr/' + cidEncrypted2 + '/' + fromHospCode + '-' + vn);
  }

  handleClick(event, id) {
    this.setState({
      anchorEl: {
        [id]: event.currentTarget,
      },
    });
  }

  handleClose(id) {
    this.setState({
      anchorEl: {
        [id]: null,
      },
    });
  }

  DialogAttachOpenFc = id => {
    this.setState({
      DialogAttachOpen: {
        [id]: true,
      },
    });

    this.handleClose(id);
  };

  DialogAttachCloseFc = id => {
    this.setState({
      DialogAttachOpen: {
        [id]: false,
      },
    });
  };

  render() {
    return (
      <div>
        <AppBar
          position='fixed'
          className='box-shadow-custom MuiAppBar-color-custom patient-main'
          style={{ top: '55px' }}
        >
          <Toolbar variant='dense' className='refer-bar'>
            <div className='text-align-left' style={{ width: '100%' }}>
              <Row>
                <Col lg={3} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                  <Row>
                    <Grid container item xs={3}>
                      <h6 className='margin-bottom-0px margin-top-8px' style={{ minWidth: '100px' }}>
                        Refer Out
                      </h6>
                    </Grid>

                    <Grid container item xs={9}>
                      <MuiPickersUtilsProvider utils={DayjsUtils} locale={'th'}>
                        <DatePicker
                          className='date-time-picker icon-button'
                          autoOk
                          value={this.state.selectedDate}
                          onChange={date => this.handleDateChange(date)}
                          disableToolbar
                          variant='inline'
                          maxDate={new Date()}
                          views={['year', 'month', 'date']}
                          format='DD/MM/yyyy'
                          yearOffset={543}
                          style={{ width: '130px' }}
                        />
                      </MuiPickersUtilsProvider>
                      <IconButton
                        className='icon-button avatar-button'
                        title='Refresh'
                        onClick={() =>
                          this.getReferDatas(
                            DateTimeToStrDate(this.state.selectedDate),
                            DateTimeToStrDate(this.state.selectedDate)
                          )
                        }
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Grid>
                  </Row>
                </Col>
                <Col lg={4} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                  <Row>
                    <Grid container item xs={8}>
                      <FormControl
                        style={{ width: '100%', marginRight: '8px' }}
                        className='margin-bottom-4px'
                        error
                        variant='filled'
                      >
                        <Select
                          labelId='hospcode-select'
                          id='hospcode-select'
                          displayEmpty
                          value={this.state.hospCode}
                          onChange={this.hospCodeChange}
                        >
                          <MenuItem value='all'>ทั้งหมด</MenuItem>
                          {this.state.hospCodeData.map((items, index) => (
                            <MenuItem key={index} value={items?.id}>
                              {items?.hospName ? items?.hospName : items?.hospCode}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid container item xs={3}>
                      <FormControl style={{ width: '100%' }} className='margin-bottom-4px' error variant='filled'>
                        <Select
                          labelId='hospcode-select'
                          id='hospcode-select'
                          displayEmpty
                          value={this.state.referPoint}
                          onChange={this.setReferPointFc}
                        >
                          <MenuItem value='all'>ทั้งหมด</MenuItem>
                          <MenuItem value='er'>- ER -</MenuItem>
                          <MenuItem value='opd'>- OPD -</MenuItem>
                          <MenuItem value='ipd'>- IPD -</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Row>
                </Col>
                <Col lg={3} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                  <form style={{ maxWidth: '300px' }} onSubmit={this.getReferCidDatas}>
                    <InputBase
                      className='margin-top-4px'
                      type='text'
                      id='cid'
                      name='cid'
                      value={this.state.cid}
                      onChange={this.onCidChange}
                      placeholder='CID / ชื่อ สกุล'
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            id='SearchPtBt'
                            type='submit'
                            className='icon-button text-color'
                            aria-label='toggle password visibility'
                            edge='end'
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </form>
                </Col>
                <Col lg={2} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() =>
                      this.getReferDatas(
                        dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
                        dayjs().format('YYYY-MM-DD'),
                        true
                      )
                    }
                  >
                    ดู refer ที่ข้อมูลไม่ครบ
                  </Button>
                </Col>
              </Row>
            </div>
          </Toolbar>
        </AppBar>
        {this.state.ReferDatas.length > 0 && (
          <div className='text-align-left' style={{ marginTop: this.props.width <= 991 ? '150px' : '45px' }}>
            <Row>
              {this.state.ReferDatas.map((items, index) => (
                <>
                  <Col key={index} lg={4} className='margin-bottom-8px'>
                    <Card variant='outlined' className={getReferColor({ referral: items })}>
                      <CardHeader
                        className='card-header'
                        avatar={
                          <Avatar
                            title={'Type : ' + items?.data?.referoutEmergencyTypeName}
                            aria-label='recipe'
                            className={` 
												${items?.data?.referoutEmergencyTypeName === 'Life threatening' && 'refer-type-icon-5'} 
												${items?.data?.referoutEmergencyTypeName === 'Emergency' && 'refer-type-icon-4'} 
												${items?.data?.referoutEmergencyTypeName === 'Urgent' && 'refer-type-icon-3'}
												${items?.data?.referoutEmergencyTypeName === 'Acute' && 'refer-type-icon-2'}
												${
                          (items?.data?.referoutEmergencyTypeName === 'Non acute' ||
                            items?.data?.referoutEmergencyTypeName === '') &&
                          'refer-type-icon-1'
                        }
												
												`}
                          >
                            {this.state.ReferDatas.length - index}
                          </Avatar>
                        }
                        action={
                          <>
                            {items?.data?.status?.toUpperCase() !== REJECTED && (
                              <>
                                <IconButton aria-label='settings' onClick={e => this.handleClick(e, index)}>
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  key={index}
                                  id={'refer-action-menu' + index}
                                  anchorEl={this.state.anchorEl[index]}
                                  keepMounted
                                  open={Boolean(this.state.anchorEl[index])}
                                  onClose={() => this.handleClose(index)}
                                  className='refer-action-menu'
                                >
                                  <MenuItem onClick={() => this.DialogAttachOpenFc(index)}>แนบไฟล์</MenuItem>
                                </Menu>
                              </>
                            )}
                          </>
                        }
                        title={'To : ' + (shortHospName(items?.toHospName) || items?.data?.toHospCode)}
                        subheader={'From : ' + (shortHospName(items?.fromHospName) || items?.data?.fromHospCode)}
                      />
                      <CardContent className='card-content'>
                        <Row>
                          <Col lg={12}>
                            <Typography component='h2' variant='h5' className='cursor-pointer'>
                              <Link
                                component='button'
                                color='secondary'
                                onClick={() =>
                                  this.emrViews(items?.data?.cid, items?.data?.fromHospCode, items?.data?.vn)
                                }
                              >
                                {items?.data?.ptname}
                              </Link>
                            </Typography>
                          </Col>
                          <Col lg={12}>
                            <Typography variant='body1' component='p'>
                              CID : {items?.data?.cid}
                            </Typography>
                          </Col>
                          <Col lg={12}>
                            <Typography variant='body2' component='p'>
                              Pre Diag : {items?.data?.preDiagnosis}
                            </Typography>
                          </Col>
                          <Col lg={12}>
                            <Typography variant='body2' component='p'>
                              ICD : {items?.data?.icd + ' ' + items?.data?.icdName}
                            </Typography>
                          </Col>
                          <Col lg={12}>
                            <Typography variant='body2' component='p'>
                              Refer Point : {items?.data?.referPoint}
                            </Typography>
                          </Col>
                          <Col lg={12}>
                            <Typography variant='body2' component='p'>
                              Refer Type : {items?.type === REFER_BACK ? 'Refer back' : 'Refer out'}
                            </Typography>
                          </Col>
                          <Col lg={12}>
                            <Typography variant='body2' component='p' className='font-size-12px'>
                              Refer Date : {ThaiDateTime(items?.data?.referDateTime)}
                            </Typography>
                          </Col>
                        </Row>
                      </CardContent>
                    </Card>
                  </Col>

                  <ReferAttachDialog
                    idToken={this?.props?.appData?.idToken}
                    open={Boolean(this?.state?.DialogAttachOpen[index])}
                    onClose={() => this.DialogAttachCloseFc(index)}
                    bid={items?.bid}
                    attachments={items?.attachments}
                  />
                </>
              ))}
            </Row>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchRefers: dataRequest => dispatch(fetchRefers(dataRequest)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferOut);
