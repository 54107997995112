import React, { Component } from 'react';
import logo from '../../images/logo MOPH.png';
import logoDark from '../../images/logo MOPH.png';
import everLogo from '../../images/ever_logo.8c71ea29.png';

// mui
import Tooltip from '@material-ui/core/Tooltip';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dark: false,
      theme: 'light',
    };
    this.changTheme = this.changTheme.bind(this);
  }

  componentDidMount(){
    localStorage.removeItem('billing-charge')
  }

  changTheme(tt) {
    if (tt === 'light') {
      this.setState({ dark: false });
      this.setState({ theme: 'light' });
    } else {
      this.setState({ dark: true });
      this.setState({ theme: 'dark' });
    }
  }

  render() {
    if (this.state.theme !== localStorage.getItem('theme')) {
      setTimeout(() => {
        this.changTheme(localStorage.getItem('theme'));
      }, 100);
    }

    return (
      <div className='home-wrapper'>
        <header>
          {localStorage.getItem('theme') === 'dark' ? (
            <img src={logo} className='App-logo' alt='logo' />
          ) : (
            <img src={logoDark} className='App-logo' alt='logo' />
          )}

          <div>
            <h5 className='text-size-3vmin'>ระบบเชื่อมต่อข้อมูลผู้ป่วยส่งต่อ จังหวัดฉะเชิงเทรา</h5>
          </div>
        </header>

        <div className='powered-by'>
          <div>
            <img src={everLogo} className='powered-by-logo' alt='logo' /> Powered By Ever
          </div>
        </div>

        <Tooltip title='Powered By Ever'>
          <img src={everLogo} className='powered-by-logo-mobile' alt='logo' />
        </Tooltip>
      </div>
    );
  }
}

export default Home;
