import axios from 'axios';
import config from 'react-global-configuration';

const DeleteHospital = async ({ idToken, hospCode }) => {
  try {
    const result = await axios.post(
      `${config.get('ROOT_API')}/v1/hospital/delete/${hospCode}`,
      {},
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
    return result;
  } catch (e) {
    throw new Error(e);
  }
};

export default DeleteHospital;
