import axios from 'axios';
import config from 'react-global-configuration';

const FetchHospMain = ({ idToken, hospCode }) => {
  let dispatch = {};
  const url = `${config.get('ROOT_API')}/v1/billing/getcachehospmain?hospCode=${hospCode}`;

  return axios
    .get(url, { headers: { Authorization: `Bearer ${idToken}` } })
    .then(res => {
      dispatch = {
        hospmains: res.data.result?.filter(item => item.hospCode !== hospCode),
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(err => {
      dispatch = {
        FetchingStatus: false,
      };
      return dispatch;
    });
};

export default FetchHospMain;
