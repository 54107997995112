import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { closeChargeModal, confirmCharged, finishCharged, finishReviewCharged } from '../../../actions/BillingsCharge';
import { fetchCalculateChargeSummary } from '../../../actions/BillingSummary';
import { Container } from './styles';

const ChargeModal = ({ selectedHosp, monthIndex, hospName, asProvider }) => {
  const dispatch = useDispatch();
  const { appData } = useSelector(state => state);
  const {
    idToken,
    loginData: { hospCode },
    billingSummary,
    chargeModal,
    calculateSummary,
    FetchingStatus,
    alert,
  } = appData;

  useEffect(() => {
    const params = {
      provider: asProvider ? hospCode : selectedHosp,
      payer: asProvider ? selectedHosp : hospCode,
      startDate: new Date(
        new Date(billingSummary.billingDate).getFullYear(),
        billingSummary.monthIndex,
        1
      ).toISOString(),
      endDate: new Date(
        new Date(billingSummary.billingDate).getFullYear(),
        Number(billingSummary.monthIndex) + 1,
        0,
        23,
        59,
        59,
        999
      ).toISOString(),
      monthIndex: Number(monthIndex) - 1,
      categoryCode: chargeModal,
      idToken,
    };
    dispatch(fetchCalculateChargeSummary(params));
  }, [idToken, billingSummary, dispatch, hospCode, selectedHosp, monthIndex, chargeModal, alert]);

  return (
    <Dialog open={chargeModal} onClose={() => dispatch(closeChargeModal())}>
      <Container>
        <div className='hosp-head'>
          {asProvider ? 'ทำการเรียกเก็บ' : 'ยืนยันการเรียกเก็บจาก'} <b>{hospName}</b>
        </div>
        <div className='total-price'>
          {asProvider ? 'จำนวนที่เรียกเก็บ' : 'จำนวนที่ถูกเรียกเก็บ'} <b>{calculateSummary && calculateSummary.totalFinalPrice}</b>
        </div>
        <div>
          <b>รายละเอียด</b>
        </div>
        {calculateSummary && (
          <div className='description'>
            {/* <div className='row top'>
              <div className='item first'>รหัสสิทธิ</div>
              <div className='item'>{calculateSummary.categoryCode}</div>
            </div> */}
            <div className='row'>
              <div className='item first'>ชื่อรหัส</div>
              <div className='item'>{calculateSummary.categoryName}</div>
            </div>
            <div className='row'>
              <div className='item first'>จำนวนผู้ป่วย</div>
              <div className='item'>{calculateSummary.totalPatients}</div>
            </div>
            <div className='row'>
              <div className='item first'>จำนวน visit</div>
              <div className='item'>{calculateSummary.totalVisits}</div>
            </div>
            <div className='row'>
              <div className='item first'>ยอดเงินตามจริง</div>
              <div className='item'>{calculateSummary.totalPrice}</div>
            </div>
            <div className='row'>
              <div className='item first'>ยอดที่คำนวณ</div>
              <div className='item'>{calculateSummary.totalCalcPrice}</div>
            </div>
            <div className='row'>
              <div className='item first'>ยอดที่เรียกเก็บ</div>
              <div className='item'>{calculateSummary.totalFinalPrice}</div>
            </div>
            {calculateSummary.status === 'REJECTED' && (
              <div className='row'>
                <div className='item first'>จำนวนที่ปฎิเสธ</div>
                <div className='item'>{calculateSummary.numRejected}</div>
              </div>
            )}
          </div>
        )}
        <div className='alert-container'>{alert.show && <Alert severity={alert.type}>{alert.msg}</Alert>}</div>
        {asProvider &&
        calculateSummary &&
        Object.keys(calculateSummary).length > 0 &&
        Number(calculateSummary.numRejected) > 0 ? (
          <h6 className='text-label'>ยังทำการแก้ไข visit ไม่ครบ</h6>
        ) : null}
        <div className='btn-container'>
          <Button
            onClick={() => {
              if (asProvider) {
                if (calculateSummary.status === 'REJECTED') {
                  dispatch(
                    finishCharged({
                      categoryObjectId: calculateSummary._id,
                      hospCode,
                      idToken,
                    })
                  );
                } else {
                  dispatch(
                    confirmCharged({
                      categoryObjectId: calculateSummary._id,
                      hospCode,
                      idToken,
                    })
                  );
                }
              } else {
                dispatch(
                  finishReviewCharged({
                    categoryObjectId: calculateSummary._id,
                    hospCode,
                    idToken,
                  })
                );
              }
            }}
            variant='contained'
            size='small'
            color='primary'
          >
            ยืนยัน
          </Button>
          <Button className='cancel' onClick={() => dispatch(closeChargeModal())} variant='contained' size='small'>
            ยกเลิก
          </Button>
        </div>
      </Container>
    </Dialog>
  );
};

export default ChargeModal;
