import axios from 'axios';
import config from 'react-global-configuration';

const EditUser = ({ idToken, body }) => {
  let dispatch = {};
  const url = `${config.get('ROOT_API')}/user`;

  return axios
    .put(url, body, { headers: { Authorization: `Bearer ${idToken}` } })
    .then(res => {
      dispatch = {
        FetchingStatus: false,
        alert: {
          show: false,
          msg: '',
          type: 'success',
        },
      };

      return dispatch;
    })
    .catch(err => {
      dispatch = {
        FetchingStatus: false,
        alert: {
          show: true,
          msg: err.response.data.msg,
          type: 'error',
        },
      };

      return dispatch;
    });
};

export default EditUser;
