import axios from 'axios';
import config from 'react-global-configuration';

export default dataRequest => {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      const { idToken, cid, hospCode, hospmain, category, categoryName, startDate, endDate, page } = dataRequest;
      const billings = await axios.get(
        `${config.get(
          'ROOT_API'
        )}/v1/billing/bypatient?cid=${cid}&hospCode=${hospCode}&hospmain=${hospmain}&category=${category}&categoryName=${categoryName}&startDate=${startDate}&endDate=${endDate}&page=${page}`,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );

      return resolve({
        billlingPatients: billings.data.result,
        FetchingStatus: false,
        patientData: billings.data.result.docs[0]?.patient,
      });
    }, 200);
  });
};
