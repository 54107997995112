import axios from 'axios';
import config from 'react-global-configuration';

export default async body => {
  const { idToken, hospCode, submissionBody, rulesSubmissionBody } = body;
  try {
    const result = await axios.post(`${config.get('ROOT_API')}/v1/billing/categories/${hospCode}`, submissionBody, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    const resultRules = await axios.post(
      `${config.get('ROOT_API')}/v1/billingRules/rules/${hospCode}`,
      rulesSubmissionBody,
      { headers: { Authorization: `Bearer ${idToken}` } }
    );
    return { result, resultRules };
  } catch (e) {
    throw new Error(e);
  }
};
