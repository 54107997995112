import _ from 'lodash';

// types
import { IReferral } from '../types/referral';

// functions
import { calculateAge } from './FuncPerjer';

export declare enum CategoryType {
  b1 = 'b1',
  b2 = 'b2',
  b3 = 'b3',
  b4 = 'b4',
}

export const setReportHeight = (barCount: number) => {
  if (barCount === 1) return 160;
  else if (barCount === 2) return 220;
  else if (barCount > 2 && barCount < 10) return barCount * 80;
  else return barCount * 40;
};

export const createBranchByIcd = ({ referData }: { referData: IReferral }) => {
  let branchs = {};

  // create branch
  if (
    (referData.data.icd.toUpperCase() >= 'S00' && referData.data.icd.toUpperCase() <= 'T98') ||
    (referData.data.icd.toUpperCase() >= 'V01' && referData.data.icd.toUpperCase() <= 'Y9899')
  ) {
    branchs = {
      id: referData._id,
      branchDx: 'b1',
      groupDx: referData.data.icd.substring(0, 1).toUpperCase(),
      icd: referData.data.icd,
      icdName: referData.data.icdName ? referData.data.icdName.replace(',', '.') : referData.data.icd,
      orderBy: 1,
    };
  }

  if (
    (referData.data.icd.toUpperCase() >= 'I20' && referData.data.icd.toUpperCase() <= 'I2599') ||
    (referData.data.icd.toUpperCase() >= 'I30' && referData.data.icd.toUpperCase() <= 'I5299')
  ) {
    branchs = {
      id: referData._id,
      branchDx: 'b2',
      groupDx: referData.data.icd.substring(0, 1).toUpperCase(),
      icd: referData.data.icd,
      icdName: referData.data.icdName ? referData.data.icdName.replace(',', '.') : referData.data.icd,
      orderBy: 2,
    };
  }

  if (
    (referData.data.icd.toUpperCase() >= 'C00' && referData.data.icd.toUpperCase() <= 'C9799') ||
    (referData.data.icd.toUpperCase() >= 'D0' && referData.data.icd.toUpperCase() <= 'D0999')
  ) {
    branchs = {
      id: referData._id,
      branchDx: 'b3',
      groupDx: referData.data.icd.substring(0, 1).toUpperCase(),
      icd: referData.data.icd,
      icdName: referData.data.icdName ? referData.data.icdName.replace(',', '.') : referData.data.icd,
      orderBy: 3,
    };
  }

  if (
    (referData.data.icd.toUpperCase() >= 'P00' && referData.data.icd.toUpperCase() <= 'P9699') ||
    (referData.data.icd.toUpperCase() >= 'Q00' && referData.data.icd.toUpperCase() <= 'Q9999')
  ) {
    branchs = {
      id: referData._id,
      branchDx: 'b4',
      groupDx: referData.data.icd.substring(0, 1).toUpperCase(),
      icd: referData.data.icd,
      icdName: referData.data.icdName ? referData.data.icdName.replace(',', '.') : referData.data.icd,
      orderBy: 4,
    };
  }

  return branchs;
};

export const innerTableDataByCategory = ({
  referDatas,
  category,
}: {
  referDatas: IReferral[];
  category: CategoryType;
}) => {
  let filteredReferDatas: any[] = [];

  switch (category) {
    case 'b1':
      filteredReferDatas = referDatas.filter(
        item =>
          (item.data.icd.toUpperCase() >= 'S00' && item.data.icd.toUpperCase() <= 'T98') ||
          (item.data.icd.toUpperCase() >= 'V01' && item.data.icd.toUpperCase() <= 'Y9899')
      );
      break;
    case 'b2':
      filteredReferDatas = referDatas.filter(
        item =>
          (item.data.icd.toUpperCase() >= 'I20' && item.data.icd.toUpperCase() <= 'I2599') ||
          (item.data.icd.toUpperCase() >= 'I30' && item.data.icd.toUpperCase() <= 'I5299')
      );
      break;
    case 'b3':
      filteredReferDatas = referDatas.filter(
        item =>
          (item.data.icd.toUpperCase() >= 'C00' && item.data.icd.toUpperCase() <= 'C9799') ||
          (item.data.icd.toUpperCase() >= 'D0' && item.data.icd.toUpperCase() <= 'D0999')
      );
      break;
    case 'b4':
      filteredReferDatas = referDatas.filter(
        item =>
          (item.data.icd.toUpperCase() >= 'P00' && item.data.icd.toUpperCase() <= 'P9699') ||
          (item.data.icd.toUpperCase() >= 'Q00' && item.data.icd.toUpperCase() <= 'Q9999')
      );
      break;

    default:
      break;
  }

  const result = filteredReferDatas.map(item => {
    return {
      fromHospName: item.fromHospName,
      toHospName: item.toHospName,
      ptname: item.ptname,
      ptAge: calculateAge(item.ptDOB),
      ptSexName: item.ptSexName,
      referPoint: item.data.referPoint,
      icd: item.data.icd + ' ' + item.data.icdName,
      refername: item.data.refername,
    };
  });

  return result;
};
