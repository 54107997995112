import React from 'react';
import PropTypes from 'prop-types';
// mui
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';

export default function SimpleDialog(props) {
  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>{props.titleText || 'Title Text'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.contentText || 'Content Text'}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()} color='secondary'>
          {props.cancelText || 'ยกเลิก'}
        </Button>
        <Button onClick={() => props.onSubmit()} color='primary'>
          {props.confirmText || 'ยืนยัน'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  open: PropTypes.bool,
  titleText: PropTypes.string,
  contentText: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onSubmit: PropTypes.any,
  onClose: PropTypes.any,
};
