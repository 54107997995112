import styled from 'styled-components';
import MTTableRow from '@material-ui/core/TableRow';
import MTTableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

export const Container = styled.div`
  text-align: initial;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin: 8px 8px 32px 8px;
  padding: 16px;
  border-radius: 8px;
  .header {
    display: flex;
  }
  .header-icon {
    margin-right: 4px;
    margin-top: 8px;
  }
  .table-head {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .pagination-table {
    display: flex;
    justify-content: flex-end;
    margin: 16px;
  }
  .year-picker {
    max-width: 70px;
    margin-left: 5px;
  }
  .title {
    line-height: 2;
  }
`;

export const Status = styled.span`
  color: ${props => props.color};
`;

export const TableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
  },
}))(MTTableCell);

export const TableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f9f9f9',
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(MTTableRow);
